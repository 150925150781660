/* tslint:disable */
/* eslint-disable */
/**
 * Alder Hey Chat API
 * This RESTful API provides an interface to the Alder Hey chatbot.
 *
 * The version of the OpenAPI document: abbb7b6
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Assistantv2RuntimeIntent
 */
export interface Assistantv2RuntimeIntent {
  /**
   * A decimal percentage that represents Watson\'s confidence in the intent.
   * @type {number}
   * @memberof Assistantv2RuntimeIntent
   */
  confidence: number;
  /**
   * The name of the recognized intent.
   * @type {string}
   * @memberof Assistantv2RuntimeIntent
   */
  intent: string;
}

export function Assistantv2RuntimeIntentFromJSON(
  json: any
): Assistantv2RuntimeIntent {
  return Assistantv2RuntimeIntentFromJSONTyped(json, false);
}

export function Assistantv2RuntimeIntentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Assistantv2RuntimeIntent {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    confidence: json["confidence"],
    intent: json["intent"],
  };
}

export function Assistantv2RuntimeIntentToJSON(
  value?: Assistantv2RuntimeIntent | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    confidence: value.confidence,
    intent: value.intent,
  };
}
