/* tslint:disable */
/* eslint-disable */
/**
 * Alder Hey Chat API
 * This RESTful API provides an interface to the Alder Hey chatbot.
 *
 * The version of the OpenAPI document: abbb7b6
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  Assistantv2DialogSuggestionValue,
  Assistantv2DialogSuggestionValueFromJSON,
  Assistantv2DialogSuggestionValueFromJSONTyped,
  Assistantv2DialogSuggestionValueToJSON,
} from "./";

/**
 *
 * @export
 * @interface Assistantv2DialogSuggestion
 */
export interface Assistantv2DialogSuggestion {
  /**
   * The user-facing label for the disambiguation option. This label is taken from the **title** or **user_label** property of the corresponding dialog node, depending on the disambiguation options.
   * @type {string}
   * @memberof Assistantv2DialogSuggestion
   */
  label: string;
  /**
   * The dialog output that will be returned from the Watson Assistant service if the user selects the corresponding option.
   * @type {object}
   * @memberof Assistantv2DialogSuggestion
   */
  output?: object;
  /**
   *
   * @type {Assistantv2DialogSuggestionValue}
   * @memberof Assistantv2DialogSuggestion
   */
  value: Assistantv2DialogSuggestionValue;
}

export function Assistantv2DialogSuggestionFromJSON(
  json: any
): Assistantv2DialogSuggestion {
  return Assistantv2DialogSuggestionFromJSONTyped(json, false);
}

export function Assistantv2DialogSuggestionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Assistantv2DialogSuggestion {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    label: json["label"],
    output: !exists(json, "output") ? undefined : json["output"],
    value: Assistantv2DialogSuggestionValueFromJSON(json["value"]),
  };
}

export function Assistantv2DialogSuggestionToJSON(
  value?: Assistantv2DialogSuggestion | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    label: value.label,
    output: value.output,
    value: Assistantv2DialogSuggestionValueToJSON(value.value),
  };
}
