/* tslint:disable */
/* eslint-disable */
/**
 * Alder Hey Chat API
 * This RESTful API provides an interface to the Alder Hey chatbot.
 *
 * The version of the OpenAPI document: abbb7b6
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  Assistantv2MessageContextGlobal,
  Assistantv2MessageContextGlobalFromJSON,
  Assistantv2MessageContextGlobalFromJSONTyped,
  Assistantv2MessageContextGlobalToJSON,
} from "./";

/**
 *
 * @export
 * @interface Assistantv2MessageContext
 */
export interface Assistantv2MessageContext {
  /**
   *
   * @type {Assistantv2MessageContextGlobal}
   * @memberof Assistantv2MessageContext
   */
  global?: Assistantv2MessageContextGlobal;
  /**
   *
   * @type {object}
   * @memberof Assistantv2MessageContext
   */
  skills?: object;
}

export function Assistantv2MessageContextFromJSON(
  json: any
): Assistantv2MessageContext {
  return Assistantv2MessageContextFromJSONTyped(json, false);
}

export function Assistantv2MessageContextFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Assistantv2MessageContext {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    global: !exists(json, "global")
      ? undefined
      : Assistantv2MessageContextGlobalFromJSON(json["global"]),
    skills: !exists(json, "skills") ? undefined : json["skills"],
  };
}

export function Assistantv2MessageContextToJSON(
  value?: Assistantv2MessageContext | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    global: Assistantv2MessageContextGlobalToJSON(value.global),
    skills: value.skills,
  };
}
