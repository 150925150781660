import "./App.css";

import { Alert } from "@mui/lab";
import {
  Container,
  Paper,
  Snackbar,
  SnackbarCloseReason,
  styled,
} from "@mui/material";
import ScopedCssBaseline from "@mui/material/ScopedCssBaseline";
import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import { useAppContext } from "./AppContext";
import { useBrand } from "./brands/Brand";
import { AppTopBar } from "./components/AppTopBar";
import { constants } from "./helpers/Constants";
import { About } from "./pages/About";
import { Chat } from "./pages/Chat";
import { Survey } from "./pages/Survey";
import { Welcome } from "./pages/Welcome";

interface CssBaselineProps {
  isFullscreen: boolean;
  isRounded: boolean;
}

const StyledScopedCssBaseline = styled(ScopedCssBaseline, {
  shouldForwardProp: prop => prop !== "isFullscreen" && prop !== "isRounded",
})<CssBaselineProps>(({ theme, isFullscreen, isRounded }) => ({
  minHeight: "100%",
  transition: "border 0.5s ease-in-out",
  height: "100%",
  overflow: isFullscreen ? "auto" : "hidden",
  width: isRounded ? "100%" : undefined,
  borderRadius: isRounded ? theme.spacing(3) : undefined,
  boxShadow: isRounded ? "2px 2px 5px gray" : undefined,
}));

const StyledContainerApp = styled(Container)(({ theme }) => ({
  height: "100%",
  backgroundColor: theme.palette.background.default,
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  height: "100%",
  backgroundColor: theme.palette.background.default,
  display: "grid",
  gridTemplateColumns: "repeat(12, 1fr)",
  gridTemplateRows: "48px 1fr",
  gridTemplateAreas: "'n n n n n n n n n n n n' 'c c c c c c c c c c c c'",
  borderRadius: 0,
}));

const StyledContentContainer = styled("div")(() => ({
  gridArea: "c",
}));

export const App = () => {
  const { snackBarError, setSnackbarError, isFullscreen } = useAppContext();
  const brand = useBrand();

  const handleErrorSnackbarClosed = (
    _event?: React.SyntheticEvent,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarError("");
  };

  useEffect(() => {
    const loader = document.getElementById("preloader");
    if (loader !== null) {
      loader.style.display = "none";
    }
  }, []);

  return (
    <StyledScopedCssBaseline
      isFullscreen={isFullscreen}
      isRounded={!isFullscreen}
    >
      <StyledContainerApp maxWidth="md" disableGutters>
        <StyledPaper elevation={0}>
          <AppTopBar title={brand.botName} gridArea="n" />
          <StyledContentContainer>
            <Routes>
              <Route path={constants.paths.about} element={<About />} />
              <Route path={constants.paths.survey} element={<Survey />} />
              <Route path={constants.paths.chat} element={<Chat />} />
              <Route path={constants.paths.welcome} element={<Welcome />} />
            </Routes>
          </StyledContentContainer>

          <Snackbar
            open={!!snackBarError.length}
            anchorOrigin={{ horizontal: "center", vertical: "top" }}
            autoHideDuration={6000}
            onClose={(_event, reason) => {
              if (reason === "clickaway") {
                return;
              }
              setSnackbarError("");
            }}
          >
            <Alert onClose={handleErrorSnackbarClosed} severity="error">
              {snackBarError}
            </Alert>
          </Snackbar>
        </StyledPaper>
      </StyledContainerApp>
    </StyledScopedCssBaseline>
  );
};
