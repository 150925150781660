/* tslint:disable */
/* eslint-disable */
/**
 * Alder Hey Chat API
 * This RESTful API provides an interface to the Alder Hey chatbot.
 *
 * The version of the OpenAPI document: abbb7b6
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  AssistantMessageInput,
  AssistantMessageInputFromJSON,
  AssistantMessageInputFromJSONTyped,
  AssistantMessageInputToJSON,
} from "./";

/**
 *
 * @export
 * @interface ViewmodelAddMessage
 */
export interface ViewmodelAddMessage {
  /**
   *
   * @type {string}
   * @memberof ViewmodelAddMessage
   */
  interaction?: ViewmodelAddMessageInteractionEnum;
  /**
   *
   * @type {AssistantMessageInput}
   * @memberof ViewmodelAddMessage
   */
  request?: AssistantMessageInput;
  /**
   *
   * @type {string}
   * @memberof ViewmodelAddMessage
   */
  userID?: string;
}

export function ViewmodelAddMessageFromJSON(json: any): ViewmodelAddMessage {
  return ViewmodelAddMessageFromJSONTyped(json, false);
}

export function ViewmodelAddMessageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ViewmodelAddMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    interaction: !exists(json, "interaction") ? undefined : json["interaction"],
    request: !exists(json, "request")
      ? undefined
      : AssistantMessageInputFromJSON(json["request"]),
    userID: !exists(json, "userID") ? undefined : json["userID"],
  };
}

export function ViewmodelAddMessageToJSON(
  value?: ViewmodelAddMessage | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    interaction: value.interaction,
    request: AssistantMessageInputToJSON(value.request),
    userID: value.userID,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum ViewmodelAddMessageInteractionEnum {
  TextInput = "text_input",
  OptionSelection = "option_selection",
  Autoprompt = "autoprompt",
}
