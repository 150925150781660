/* tslint:disable */
/* eslint-disable */
/**
 * Alder Hey Chat API
 * This RESTful API provides an interface to the Alder Hey chatbot.
 *
 * The version of the OpenAPI document: abbb7b6
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ViewmodelAddConversation
 */
export interface ViewmodelAddConversation {
  /**
   *
   * @type {number}
   * @memberof ViewmodelAddConversation
   */
  accountId?: number;
  /**
   *
   * @type {string}
   * @memberof ViewmodelAddConversation
   */
  clientName?: string;
}

export function ViewmodelAddConversationFromJSON(
  json: any
): ViewmodelAddConversation {
  return ViewmodelAddConversationFromJSONTyped(json, false);
}

export function ViewmodelAddConversationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ViewmodelAddConversation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    accountId: !exists(json, "accountId") ? undefined : json["accountId"],
    clientName: !exists(json, "clientName") ? undefined : json["clientName"],
  };
}

export function ViewmodelAddConversationToJSON(
  value?: ViewmodelAddConversation | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    accountId: value.accountId,
    clientName: value.clientName,
  };
}
