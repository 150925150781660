import { v4 as uuidv4 } from "uuid";

import { getConversationsAPI } from "../api_client";
import {
  ChatbotMessageInfo,
  ConversationInfo,
  MessageInfo,
} from "../typings/chat_types";
import { constants } from "./Constants";

const SESSION_STORAGE_KEY = "@AH_CHAT_FRONTEND:session";
const CURRENT_SESSION_VERSION = 2;

export interface SessionStorage {
  version: number; // If session version increases, we invalidate any existing sessions.
  accountId: number;
  conversation?: ConversationInfo;
  userId?: string; // Optional UUID used for measuring unique users by Assistant API "Plus" plan.
  messages: MessageInfo[];
  messageQueue: ChatbotMessageInfo[];
  expiryDate: Date;
}

/**
 * Session that syncs to local storage for persistence.
 *
 * **Important:** You should not pass this 'Session' as a dependency in a dependency
 * array for e.g. React.useEffect() as this will serialise and de-serialise, leading to
 * the session storage object being stale and getting strange errors where persisting
 * one property will change the others.
 */
export class Session {
  /* eslint-disable-next-line no-use-before-define */
  private static instance: Session | undefined;

  private readonly storage: Storage | undefined;

  private readonly session: SessionStorage = {
    version: CURRENT_SESSION_VERSION,
    accountId: -1,
    userId: constants.enableUserId ? uuidv4() : undefined,
    conversation: undefined,
    messages: [],
    messageQueue: [],
    expiryDate: new Date(),
  };

  private constructor() {
    try {
      this.storage =
        window.localStorage !== null ? window.localStorage : undefined;
    } catch (e) {
      if (e instanceof DOMException) {
        // This means user has blocked cookies for whatever reason - we should handle
        // this gracefully. Chrome and Safari throw this error whereas Firefox just sets
        // `window.localStorage` to null.
      } else {
        throw e;
      }
    }

    if (this.storage === undefined) {
      console.warn(
        "[ah-chat] User has disabled cookies, local storage persistence will be disabled."
      );
    }

    const storedSession = this.loadSession();
    if (storedSession !== undefined) {
      this.session = storedSession;
    }
    this.storeSession();
  }

  public static getInstance() {
    if (this.instance === undefined) {
      this.instance = new Session();
    }

    return this.instance;
  }

  public hasValidConversation() {
    const storedConversation = this.conversation;

    const now = new Date();
    if (storedConversation !== undefined && this.expiryDate > now) {
      return true;
    }

    return false;
  }

  public async startNewConversation(clientName: string, tenant?: string) {
    const conversationApi = getConversationsAPI();
    const newConversation = await conversationApi.addConversation({
      addConversation: {
        accountId: this.accountId,
        clientName,
      },
      aHTenantName: tenant,
    });

    this.conversation = newConversation;
    this.extendConversationExpiry();

    return newConversation;
  }

  public clearConversation() {
    this.accountId = -1;
    this.conversation = undefined;
    this.messages = [];
    this.messageQueue = [];
    this.expiryDate = new Date();
  }

  public extendConversationExpiry() {
    const now = new Date();

    // We have timeout in minutes where Date uses milliseconds
    const timeoutMs =
      (this.conversation?.conversationTimeoutMinutes ?? 0) * 60 * 1000;

    this.expiryDate = new Date(now.getTime() + timeoutMs);
  }

  public get accountId() {
    return this.session.accountId;
  }

  public set accountId(value: number) {
    this.session.accountId = value;
    this.storeSession();
  }

  public get conversation() {
    return this.session.conversation;
  }

  public set conversation(value: ConversationInfo | undefined) {
    this.session.conversation = value;
    this.storeSession();
  }

  public get userId() {
    return this.session.userId;
  }

  public set userId(value: string | undefined) {
    this.session.userId = value;
    this.storeSession();
  }

  public get messages() {
    return this.session.messages;
  }

  public set messages(value: MessageInfo[]) {
    this.session.messages = value;
    this.storeSession();
  }

  public get messageQueue() {
    return this.session.messageQueue;
  }

  public set messageQueue(value: ChatbotMessageInfo[]) {
    this.session.messageQueue = value;
    this.storeSession();
  }

  public get expiryDate() {
    return new Date(this.session.expiryDate);
  }

  public set expiryDate(value: Date) {
    this.session.expiryDate = value;
    this.storeSession();
  }

  private loadSession() {
    if (this.storage === undefined) {
      return undefined;
    }

    const storedSessionJson = this.storage.getItem(SESSION_STORAGE_KEY);
    if (storedSessionJson === null) {
      // We don't have any stored session, so persist this new one.
      return undefined;
    }

    // If version doesn't match, we ignore the stored session and stomp over it.
    const storedSession = JSON.parse(storedSessionJson);
    if (storedSession.version !== this.session.version) {
      return undefined;
    }

    return storedSession as SessionStorage;
  }

  private storeSession() {
    if (this.storage === undefined) {
      return;
    }

    this.storage.setItem(SESSION_STORAGE_KEY, JSON.stringify(this.session));
  }
}
