import React, { FunctionComponent } from "react";

import { MessageSide } from "../typings/chat_types";
import { MessageText } from "./MessageText";

interface UserMessageTextProps {
  isFirstItem: boolean;
  message: string;
}

export const UserMessageText: FunctionComponent<UserMessageTextProps> = ({
  isFirstItem = false,
  message,
}) => (
  <MessageText
    message={message}
    side={MessageSide.Right}
    allowHTML={false}
    isFirstItem={isFirstItem}
  />
);
