import { CircularProgress, Grid } from "@mui/material";
import React, { FunctionComponent } from "react";

export const Loading: FunctionComponent = () => {
  return (
    <Grid
      container
      spacing={1}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100%" }}
    >
      <Grid item xs={6}>
        <CircularProgress />
      </Grid>
    </Grid>
  );
};
