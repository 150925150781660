import { Grow } from "@mui/material";
import React, { FunctionComponent } from "react";

import { ViewmodelAddMessageInteractionEnum as InteractionType } from "../api_client";
import { useBrand } from "../brands/Brand";
import { ChatbotMessageImage } from "../components/ChatbotMessageImage";
import { ChatbotMessageOptions } from "../components/ChatbotMessageOptions";
import { ChatbotMessageSearch } from "../components/ChatbotMessageSearch";
import { ChatbotMessageText } from "../components/ChatbotMessageText";
import { UserMessageText } from "../components/UserMessageText";
import {
  MessageAuthor,
  MessageInfo,
  MessageSide,
  UserMessageInput,
} from "../typings/chat_types";
import { IframeMessage } from "./ChatbotMessageIframe";

interface ChatMessageProps {
  isFirstItem?: boolean;
  isLastItem?: boolean;
  message: MessageInfo;
  onOptionSelection: (selection: UserMessageInput) => void;
  onRatingChanged: (newRating?: number) => void;
}

const numberOfSearchResultsShown = 2;

export const ChatMessage: FunctionComponent<ChatMessageProps> = ({
  isFirstItem,
  isLastItem,
  message,
  onOptionSelection,
  onRatingChanged,
}) => {
  const brand = useBrand();
  const innerMessage = getInnerMessage({
    isFirstItem,
    isLastItem,
    message,
    onOptionSelection,
    onRatingChanged,
    showMessageRating: brand.ui.showMessageRating,
  });

  if (innerMessage === null) {
    return null;
  }

  const side =
    message.author === MessageAuthor.Chatbot &&
    message.responseType !== "option"
      ? MessageSide.Left
      : MessageSide.Right;

  // The element inside the <Grow> needs to accept the `style` attribute, hence the
  // `<div>...</div>`.
  return (
    <Grow
      in={true}
      timeout={500}
      style={{
        transformOrigin: side === MessageSide.Left ? "top left" : "top right",
      }}
    >
      <div>{innerMessage}</div>
    </Grow>
  );
};

const getInnerMessage = ({
  isFirstItem = false,
  isLastItem = false,
  message,
  onOptionSelection,
  onRatingChanged,
  showMessageRating,
}: {
  isFirstItem?: boolean;
  isLastItem?: boolean;
  message: MessageInfo;
  onOptionSelection: (selection: UserMessageInput) => void;
  onRatingChanged: (newRating?: number) => void;
  showMessageRating: boolean;
}) => {
  if (message.author === MessageAuthor.User) {
    if (message.interaction === InteractionType.OptionSelection) {
      return null;
    }
    return (
      <UserMessageText
        message={message.request!.text!}
        isFirstItem={isFirstItem}
      />
    );
  }

  switch (message.responseType) {
    case "text":
      return (
        <ChatbotMessageText
          isFirstItem={isFirstItem}
          isLastItem={isLastItem}
          message={message.text!}
          attribution={message.attribution}
          showRating={showMessageRating}
          ratingValue={message.rating}
          onRatingChanged={onRatingChanged}
        />
      );
    case "options":
      return (
        <ChatbotMessageOptions
          side={MessageSide.Right}
          title={message.title}
          description={message.description}
          onOptionSelected={selection => onOptionSelection(selection.input!)}
          options={message.options!}
          disabled={message.disabled}
        />
      );
    case "multioptions":
      return (
        <ChatbotMessageOptions
          side={MessageSide.Right}
          title={message.title}
          description={message.description}
          onOptionSelected={selection => onOptionSelection(selection.input!)}
          options={message.options!}
          disabled={message.disabled}
        />
      );
    case "pause":
      // The pause message type is handled elsewhere, so shouldn't actually appear here,
      // but don't worry if it does.
      return null;
    case "image":
      return (
        <ChatbotMessageImage
          title={message.title}
          description={message.description}
          source={message.source!}
        />
      );
    case "search":
      return (
        <ChatbotMessageSearch
          side={MessageSide.Left}
          header={message.header}
          results={message.results!}
          numResultsToDisplay={numberOfSearchResultsShown}
          isFirstItem={isFirstItem}
        />
      );
    case "suggestion":
      return (
        <>
          <ChatbotMessageText
            message={message.title!}
            isFirstItem={isFirstItem}
          />
          <ChatbotMessageOptions
            side={MessageSide.Right}
            options={message.suggestions!}
            disabled={message.disabled}
            onOptionSelected={selection => onOptionSelection(selection.input!)}
          />
        </>
      );
    case "iframe":
      return <IframeMessage src={message.source!} title={message.title} />;
    default:
      console.warn("[ah-chat] Unknown chatbot message type:", message);
      return null;
  }
};
