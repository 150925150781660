import { Typography, styled } from "@mui/material";
import React, { FunctionComponent } from "react";

import { MessageSide } from "../typings/chat_types";
import { MessageRating } from "./MessageRating";
import { MessageText } from "./MessageText";

const Attribution = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

interface ChatbotMessageTextProps {
  isFirstItem?: boolean;
  isLastItem?: boolean;
  msgIndex?: number;
  message: string;
  showRating?: boolean;
  ratingValue?: number;
  onRatingChanged?: (newRating?: number) => void;
  attribution?: string;
}

export const ChatbotMessageText: FunctionComponent<ChatbotMessageTextProps> = ({
  isFirstItem = false,
  isLastItem = false,
  message,
  showRating,
  ratingValue,
  onRatingChanged,
  attribution,
}) => {
  return (
    <>
      {attribution && (
        <Attribution variant="subtitle1">{attribution}</Attribution>
      )}
      <MessageText
        isFirstItem={isFirstItem}
        message={message}
        side={MessageSide.Left}
        allowHTML={true}
      >
        {showRating && isLastItem && (
          <MessageRating
            value={ratingValue}
            onChanged={onRatingChanged ?? (() => {})}
          />
        )}
      </MessageText>
    </>
  );
};
