export const getRandomRange = (min: number, max: number) => {
  return Math.random() * (max - min) + min;
};

export const redactPII = (question: string) => {
  const postCodeRegex =
    // eslint-disable-next-line max-len
    /\b(GIR ?0AA|SAN ?TA1|(?:[A-PR-UWYZ](?:\d{0,2}|[A-HK-Y]\d|[A-HK-Y]\d\d|\d[A-HJKSTUW]|[A-HK-Y]\d[ABEHMNPRV-Y])) ?\d[ABD-HJLNP-UW-Z]{2})\b/gim;
  const emailRegex =
    // eslint-disable-next-line max-len
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gim;
  const phoneRegex =
    // eslint-disable-next-line max-len
    /(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?/gim;

  return question
    .replace(postCodeRegex, "AA1 1AA")
    .replace(emailRegex, "example@example.com")
    .replace(phoneRegex, "0000 000000");
};

export const countAuthorAndTurnId = (
  arr: any[],
  authorId: number,
  turnId: string
): number => {
  return arr.reduce((count, item) => {
    return item.author === authorId &&
      item.turnId === turnId &&
      item.responseType !== "options" &&
      item.responseType !== "multioptions"
      ? count + 1
      : count;
  }, 0);
};
