import PersonIcon from "@mui/icons-material/Person";
import { Avatar, Grid, styled } from "@mui/material";
import React, { FunctionComponent } from "react";

import { useAppContext } from "../AppContext";
import { useBrand } from "../brands/Brand";
import { MessageAuthor } from "../typings/chat_types";

const AvatarSide = styled("div")(({ theme }) => ({
  width: theme.spacing(6),
  height: theme.spacing(6),
  position: "relative",
}));
const BadgeCustom = styled("span")(({ theme }) => ({
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  "&::after": {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    backgroundColor: "#44b700",
    position: "absolute",
    bottom: -5,
    right: -4,
    borderRadius: "50%",
    border: "1px solid currentColor",
    content: '""',
  },
}));
const StyledMessageGroup = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    marginTop: 0,
  },
  position: "relative",
}));
const UserIcon = styled(PersonIcon)(({ theme }) => ({
  color:
    theme.palette.mode === "light"
      ? theme.palette.secondary.light
      : theme.palette.secondary.dark,
  padding: 0,
  fontSize: 40,
}));

interface MessageGroupProps {
  author: MessageAuthor;
  showAvatar?: boolean; // Default is true
  interactionType?: string; // if the message group author is chatbot, it will be undefined
}

export const MessageGroup: FunctionComponent<MessageGroupProps> = ({
  author,
  showAvatar,
  children,
  interactionType,
}) => {
  const { isFullscreen } = useAppContext();
  const brand = useBrand();

  const avatarImg = brand.ui.images.chatAvatar;

  const shouldShowAvatar =
    showAvatar !== false &&
    avatarImg !== undefined &&
    author === MessageAuthor.Chatbot;

  return (
    <>
      {author === MessageAuthor.Chatbot && (
        <Grid container justifyContent="flex-start" spacing={2}>
          {isFullscreen && (
            <Grid item>
              <AvatarSide>
                {shouldShowAvatar && (
                  <>
                    <Avatar src={avatarImg} />
                    <BadgeCustom />
                  </>
                )}
              </AvatarSide>
            </Grid>
          )}
          <StyledMessageGroup item xs={isFullscreen ? 11 : 12}>
            {children}
          </StyledMessageGroup>
        </Grid>
      )}
      {author === MessageAuthor.User &&
        interactionType !== "option_selection" &&
        interactionType !== undefined && (
          <Grid container spacing={2} style={{ flexDirection: "row-reverse" }}>
            {isFullscreen && (
              <Grid item>
                <AvatarSide>
                  <UserIcon />
                </AvatarSide>
              </Grid>
            )}
            <StyledMessageGroup item xs={isFullscreen ? 11 : 12}>
              {children}
            </StyledMessageGroup>
          </Grid>
        )}
    </>
  );
};
