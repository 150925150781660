/* tslint:disable */
/* eslint-disable */
/**
 * Alder Hey Chat API
 * This RESTful API provides an interface to the Alder Hey chatbot.
 *
 * The version of the OpenAPI document: abbb7b6
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  AssistantMessageOutput,
  AssistantMessageOutputFromJSON,
  AssistantMessageOutputFromJSONTyped,
  AssistantMessageOutputToJSON,
  Assistantv2MessageContext,
  Assistantv2MessageContextFromJSON,
  Assistantv2MessageContextFromJSONTyped,
  Assistantv2MessageContextToJSON,
} from "./";

/**
 *
 * @export
 * @interface AssistantMessageResponse
 */
export interface AssistantMessageResponse {
  /**
   *
   * @type {Assistantv2MessageContext}
   * @memberof AssistantMessageResponse
   */
  context?: Assistantv2MessageContext;
  /**
   *
   * @type {AssistantMessageOutput}
   * @memberof AssistantMessageResponse
   */
  output: AssistantMessageOutput;
}

export function AssistantMessageResponseFromJSON(
  json: any
): AssistantMessageResponse {
  return AssistantMessageResponseFromJSONTyped(json, false);
}

export function AssistantMessageResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AssistantMessageResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    context: !exists(json, "context")
      ? undefined
      : Assistantv2MessageContextFromJSON(json["context"]),
    output: AssistantMessageOutputFromJSON(json["output"]),
  };
}

export function AssistantMessageResponseToJSON(
  value?: AssistantMessageResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    context: Assistantv2MessageContextToJSON(value.context),
    output: AssistantMessageOutputToJSON(value.output),
  };
}
