/* tslint:disable */
/* eslint-disable */
/**
 * Alder Hey Chat API
 * This RESTful API provides an interface to the Alder Hey chatbot.
 *
 * The version of the OpenAPI document: abbb7b6
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  EchoHTTPError,
  EchoHTTPErrorFromJSON,
  EchoHTTPErrorToJSON,
  ViewmodelAddSurvey,
  ViewmodelAddSurveyFromJSON,
  ViewmodelAddSurveyToJSON,
} from "../models";
import * as runtime from "../runtime";

export interface AddSurveyRequest {
  conversationID: string;
  addSurvey: ViewmodelAddSurvey;
  aHTenantName?: string;
}

/**
 * no description
 */
export class SurveysApi extends runtime.BaseAPI {
  /**
   * Add a user-completed survey to the specified conversation.
   * Submit user survey
   */
  async addSurveyRaw(
    requestParameters: AddSurveyRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.conversationID === null ||
      requestParameters.conversationID === undefined
    ) {
      throw new runtime.RequiredError(
        "conversationID",
        "Required parameter requestParameters.conversationID was null or undefined when calling addSurvey."
      );
    }

    if (
      requestParameters.addSurvey === null ||
      requestParameters.addSurvey === undefined
    ) {
      throw new runtime.RequiredError(
        "addSurvey",
        "Required parameter requestParameters.addSurvey was null or undefined when calling addSurvey."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      requestParameters.aHTenantName !== undefined &&
      requestParameters.aHTenantName !== null
    ) {
      headerParameters["AH-Tenant-Name"] = String(
        requestParameters.aHTenantName
      );
    }

    const response = await this.request({
      path: `/conversations/{conversationID}/surveys`.replace(
        `{${"conversationID"}}`,
        encodeURIComponent(String(requestParameters.conversationID))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ViewmodelAddSurveyToJSON(requestParameters.addSurvey),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Add a user-completed survey to the specified conversation.
   * Submit user survey
   */
  async addSurvey(requestParameters: AddSurveyRequest): Promise<void> {
    await this.addSurveyRaw(requestParameters);
  }
}
