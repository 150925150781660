/* tslint:disable */
/* eslint-disable */
/**
 * Alder Hey Chat API
 * This RESTful API provides an interface to the Alder Hey chatbot.
 *
 * The version of the OpenAPI document: abbb7b6
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  Assistantv2DialogNodeOutputOptionsElement,
  Assistantv2DialogNodeOutputOptionsElementFromJSON,
  Assistantv2DialogNodeOutputOptionsElementFromJSONTyped,
  Assistantv2DialogNodeOutputOptionsElementToJSON,
  Assistantv2DialogSuggestion,
  Assistantv2DialogSuggestionFromJSON,
  Assistantv2DialogSuggestionFromJSONTyped,
  Assistantv2DialogSuggestionToJSON,
  Assistantv2SearchResult,
  Assistantv2SearchResultFromJSON,
  Assistantv2SearchResultFromJSONTyped,
  Assistantv2SearchResultToJSON,
} from "./";

/**
 *
 * @export
 * @interface AssistantRuntimeResponseGeneric
 */
export interface AssistantRuntimeResponseGeneric {
  /**
   *
   * @type {string}
   * @memberof AssistantRuntimeResponseGeneric
   */
  attribution?: string;
  /**
   *
   * @type {object}
   * @memberof AssistantRuntimeResponseGeneric
   */
  custom?: object;
  /**
   * The description to show with the the response.
   * @type {string}
   * @memberof AssistantRuntimeResponseGeneric
   */
  description?: string;
  /**
   * The title or introductory text to show before the response. This text is defined in the search skill configuration.
   * @type {string}
   * @memberof AssistantRuntimeResponseGeneric
   */
  header?: string;
  /**
   * A message to be sent to the human agent who will be taking over the conversation.
   * @type {string}
   * @memberof AssistantRuntimeResponseGeneric
   */
  messageToHumanAgent?: string;
  /**
   * An array of objects describing the options from which the user can choose.
   * @type {Array<Assistantv2DialogNodeOutputOptionsElement>}
   * @memberof AssistantRuntimeResponseGeneric
   */
  options?: Array<Assistantv2DialogNodeOutputOptionsElement>;
  /**
   * The preferred type of control to display.
   * @type {string}
   * @memberof AssistantRuntimeResponseGeneric
   */
  preference?: string;
  /**
   * The type of response returned by the dialog node. The specified response type must be supported by the client application or channel.  **Note:** The **suggestion** response type is part of the disambiguation feature, which is only available for Premium users.
   * @type {string}
   * @memberof AssistantRuntimeResponseGeneric
   */
  responseType: string;
  /**
   * An array of objects containing search results.
   * @type {Array<Assistantv2SearchResult>}
   * @memberof AssistantRuntimeResponseGeneric
   */
  results?: Array<Assistantv2SearchResult>;
  /**
   * The URL of the image.
   * @type {string}
   * @memberof AssistantRuntimeResponseGeneric
   */
  source?: string;
  /**
   * An array of objects describing the possible matching dialog nodes from which the user can choose.  **Note:** The **suggestions** property is part of the disambiguation feature, which is only available for Premium users.
   * @type {Array<Assistantv2DialogSuggestion>}
   * @memberof AssistantRuntimeResponseGeneric
   */
  suggestions?: Array<Assistantv2DialogSuggestion>;
  /**
   * The text of the response.
   * @type {string}
   * @memberof AssistantRuntimeResponseGeneric
   */
  text?: string;
  /**
   * How long to pause, in milliseconds.
   * @type {number}
   * @memberof AssistantRuntimeResponseGeneric
   */
  time?: number;
  /**
   * The title or introductory text to show before the response.
   * @type {string}
   * @memberof AssistantRuntimeResponseGeneric
   */
  title?: string;
  /**
   * A label identifying the topic of the conversation, derived from the **user_label** property of the relevant node.
   * @type {string}
   * @memberof AssistantRuntimeResponseGeneric
   */
  topic?: string;
  /**
   * Whether to send a \"user is typing\" event during the pause.
   * @type {boolean}
   * @memberof AssistantRuntimeResponseGeneric
   */
  typing?: boolean;
}

export function AssistantRuntimeResponseGenericFromJSON(
  json: any
): AssistantRuntimeResponseGeneric {
  return AssistantRuntimeResponseGenericFromJSONTyped(json, false);
}

export function AssistantRuntimeResponseGenericFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AssistantRuntimeResponseGeneric {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    attribution: !exists(json, "attribution") ? undefined : json["attribution"],
    custom: !exists(json, "custom") ? undefined : json["custom"],
    description: !exists(json, "description") ? undefined : json["description"],
    header: !exists(json, "header") ? undefined : json["header"],
    messageToHumanAgent: !exists(json, "message_to_human_agent")
      ? undefined
      : json["message_to_human_agent"],
    options: !exists(json, "options")
      ? undefined
      : (json["options"] as Array<any>).map(
          Assistantv2DialogNodeOutputOptionsElementFromJSON
        ),
    preference: !exists(json, "preference") ? undefined : json["preference"],
    responseType: json["response_type"],
    results: !exists(json, "results")
      ? undefined
      : (json["results"] as Array<any>).map(Assistantv2SearchResultFromJSON),
    source: !exists(json, "source") ? undefined : json["source"],
    suggestions: !exists(json, "suggestions")
      ? undefined
      : (json["suggestions"] as Array<any>).map(
          Assistantv2DialogSuggestionFromJSON
        ),
    text: !exists(json, "text") ? undefined : json["text"],
    time: !exists(json, "time") ? undefined : json["time"],
    title: !exists(json, "title") ? undefined : json["title"],
    topic: !exists(json, "topic") ? undefined : json["topic"],
    typing: !exists(json, "typing") ? undefined : json["typing"],
  };
}

export function AssistantRuntimeResponseGenericToJSON(
  value?: AssistantRuntimeResponseGeneric | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    attribution: value.attribution,
    custom: value.custom,
    description: value.description,
    header: value.header,
    message_to_human_agent: value.messageToHumanAgent,
    options:
      value.options === undefined
        ? undefined
        : (value.options as Array<any>).map(
            Assistantv2DialogNodeOutputOptionsElementToJSON
          ),
    preference: value.preference,
    response_type: value.responseType,
    results:
      value.results === undefined
        ? undefined
        : (value.results as Array<any>).map(Assistantv2SearchResultToJSON),
    source: value.source,
    suggestions:
      value.suggestions === undefined
        ? undefined
        : (value.suggestions as Array<any>).map(
            Assistantv2DialogSuggestionToJSON
          ),
    text: value.text,
    time: value.time,
    title: value.title,
    topic: value.topic,
    typing: value.typing,
  };
}
