/* tslint:disable */
/* eslint-disable */
/**
 * Alder Hey Chat API
 * This RESTful API provides an interface to the Alder Hey chatbot.
 *
 * The version of the OpenAPI document: abbb7b6
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ViewmodelPatchConversation
 */
export interface ViewmodelPatchConversation {
  /**
   *
   * @type {number}
   * @memberof ViewmodelPatchConversation
   */
  finishedAt?: number;
  /**
   *
   * @type {number}
   * @memberof ViewmodelPatchConversation
   */
  score?: number;
}

export function ViewmodelPatchConversationFromJSON(
  json: any
): ViewmodelPatchConversation {
  return ViewmodelPatchConversationFromJSONTyped(json, false);
}

export function ViewmodelPatchConversationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ViewmodelPatchConversation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    finishedAt: !exists(json, "finishedAt") ? undefined : json["finishedAt"],
    score: !exists(json, "score") ? undefined : json["score"],
  };
}

export function ViewmodelPatchConversationToJSON(
  value?: ViewmodelPatchConversation | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    finishedAt: value.finishedAt,
    score: value.score,
  };
}
