/* tslint:disable */
/* eslint-disable */
/**
 * Alder Hey Chat API
 * This RESTful API provides an interface to the Alder Hey chatbot.
 *
 * The version of the OpenAPI document: abbb7b6
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  Assistantv2MessageInputOptions,
  Assistantv2MessageInputOptionsFromJSON,
  Assistantv2MessageInputOptionsFromJSONTyped,
  Assistantv2MessageInputOptionsToJSON,
  Assistantv2RuntimeEntity,
  Assistantv2RuntimeEntityFromJSON,
  Assistantv2RuntimeEntityFromJSONTyped,
  Assistantv2RuntimeEntityToJSON,
  Assistantv2RuntimeIntent,
  Assistantv2RuntimeIntentFromJSON,
  Assistantv2RuntimeIntentFromJSONTyped,
  Assistantv2RuntimeIntentToJSON,
} from "./";

/**
 *
 * @export
 * @interface AssistantMessageInput
 */
export interface AssistantMessageInput {
  /**
   * Entities to use when evaluating the message. Include entities from the previous response to continue using those entities rather than detecting entities in the new input.
   * @type {Array<Assistantv2RuntimeEntity>}
   * @memberof AssistantMessageInput
   */
  entities?: Array<Assistantv2RuntimeEntity>;
  /**
   * Intents to use when evaluating the user input. Include intents from the previous response to continue using those intents rather than trying to recognize intents in the new input.
   * @type {Array<Assistantv2RuntimeIntent>}
   * @memberof AssistantMessageInput
   */
  intents?: Array<Assistantv2RuntimeIntent>;
  /**
   * The type of user input. Currently, only text input is supported.
   * @type {string}
   * @memberof AssistantMessageInput
   */
  messageType?: string;
  /**
   *
   * @type {Assistantv2MessageInputOptions}
   * @memberof AssistantMessageInput
   */
  options?: Assistantv2MessageInputOptions;
  /**
   * For internal use only.
   * @type {string}
   * @memberof AssistantMessageInput
   */
  suggestionId?: string;
  /**
   * The text of the user input. This string cannot contain carriage return, newline, or tab characters.
   * @type {string}
   * @memberof AssistantMessageInput
   */
  text?: string;
}

export function AssistantMessageInputFromJSON(
  json: any
): AssistantMessageInput {
  return AssistantMessageInputFromJSONTyped(json, false);
}

export function AssistantMessageInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AssistantMessageInput {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    entities: !exists(json, "entities")
      ? undefined
      : (json["entities"] as Array<any>).map(Assistantv2RuntimeEntityFromJSON),
    intents: !exists(json, "intents")
      ? undefined
      : (json["intents"] as Array<any>).map(Assistantv2RuntimeIntentFromJSON),
    messageType: !exists(json, "message_type")
      ? undefined
      : json["message_type"],
    options: !exists(json, "options")
      ? undefined
      : Assistantv2MessageInputOptionsFromJSON(json["options"]),
    suggestionId: !exists(json, "suggestion_id")
      ? undefined
      : json["suggestion_id"],
    text: !exists(json, "text") ? undefined : json["text"],
  };
}

export function AssistantMessageInputToJSON(
  value?: AssistantMessageInput | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    entities:
      value.entities === undefined
        ? undefined
        : (value.entities as Array<any>).map(Assistantv2RuntimeEntityToJSON),
    intents:
      value.intents === undefined
        ? undefined
        : (value.intents as Array<any>).map(Assistantv2RuntimeIntentToJSON),
    message_type: value.messageType,
    options: Assistantv2MessageInputOptionsToJSON(value.options),
    suggestion_id: value.suggestionId,
    text: value.text,
  };
}
