/* tslint:disable */
/* eslint-disable */
/**
 * Alder Hey Chat API
 * This RESTful API provides an interface to the Alder Hey chatbot.
 *
 * The version of the OpenAPI document: abbb7b6
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Assistantv2DialogNodeAction
 */
export interface Assistantv2DialogNodeAction {
  /**
   * The name of the context variable that the client application will use to pass in credentials for the action.
   * @type {string}
   * @memberof Assistantv2DialogNodeAction
   */
  credentials?: string;
  /**
   * The name of the action.
   * @type {string}
   * @memberof Assistantv2DialogNodeAction
   */
  name: string;
  /**
   * A map of key/value pairs to be provided to the action.
   * @type {object}
   * @memberof Assistantv2DialogNodeAction
   */
  parameters?: object;
  /**
   * The location in the dialog context where the result of the action is stored.
   * @type {string}
   * @memberof Assistantv2DialogNodeAction
   */
  resultVariable: string;
  /**
   * The type of action to invoke.
   * @type {string}
   * @memberof Assistantv2DialogNodeAction
   */
  type?: string;
}

export function Assistantv2DialogNodeActionFromJSON(
  json: any
): Assistantv2DialogNodeAction {
  return Assistantv2DialogNodeActionFromJSONTyped(json, false);
}

export function Assistantv2DialogNodeActionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Assistantv2DialogNodeAction {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    credentials: !exists(json, "credentials") ? undefined : json["credentials"],
    name: json["name"],
    parameters: !exists(json, "parameters") ? undefined : json["parameters"],
    resultVariable: json["result_variable"],
    type: !exists(json, "type") ? undefined : json["type"],
  };
}

export function Assistantv2DialogNodeActionToJSON(
  value?: Assistantv2DialogNodeAction | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    credentials: value.credentials,
    name: value.name,
    parameters: value.parameters,
    result_variable: value.resultVariable,
    type: value.type,
  };
}
