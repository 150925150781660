import { Grid } from "@mui/material";
import React, { FunctionComponent } from "react";

import { Assistantv2SearchResult } from "../api_client";
import { MessageSide } from "../typings/chat_types";
import { ChatbotMessageSearchCard } from "./ChatbotMessageSearchCard";

interface ChatbotMessageSearchCardGridProps {
  results: Assistantv2SearchResult[];
  side: MessageSide;
  numResultsToDisplay: number;
}

export const ChatbotMessageSearchCardGrid: FunctionComponent<
  ChatbotMessageSearchCardGridProps
> = ({ results, side, numResultsToDisplay }) => {
  const resultsGridItems = results
    .slice(0, numResultsToDisplay)
    .map((result: Assistantv2SearchResult, resultIndex: number) => (
      <ChatbotMessageSearchCard key={resultIndex} result={result} side={side} />
    ));
  return (
    <Grid container spacing={3}>
      {resultsGridItems}
    </Grid>
  );
};
