// Unbranded
import React from "react";

import DefaultChatbotAvatar from "../static/default_bot_img.png";
import { Brand } from "./Brand";
import { defaults } from "./BrandDefaults";

const appName = "Chatbot App";

export const unbranded: Brand = {
  botName: "Bot",
  appName,
  provider: "Hartree Centre",
  ui: {
    images: {
      chatAvatar: DefaultChatbotAvatar,
    },
    showAboutLink: true,
    showMessageRating: false,
  },
  welcome: defaults.welcome,
  about: {
    termsOfUseStatementsExpanded: [
      `Chatbot App is a digital assistant or chatbot which can provide general information \
        for patients and their families about visiting Hospital.`,

      "It does not provide tailored, diagnostic or personal medical advice.",

      `By using the service you confirm that you understand the limitations of the \
        information provided.`,
    ],
    questions: defaults.questions,
    escalation: (
      <defaults.about.escalation appName={appName} companyName="us" />
    ),
  },
  theme: {
    primaryColour: "#8e44ad",
    secondaryColour: "#e67e22",
  },
  enableSurveys: true,
};
