/* tslint:disable */
/* eslint-disable */
/**
 * Alder Hey Chat API
 * This RESTful API provides an interface to the Alder Hey chatbot.
 *
 * The version of the OpenAPI document: abbb7b6
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  Assistantv2MessageInput,
  Assistantv2MessageInputFromJSON,
  Assistantv2MessageInputFromJSONTyped,
  Assistantv2MessageInputToJSON,
} from "./";

/**
 *
 * @export
 * @interface Assistantv2DialogNodeOutputOptionsElementValue
 */
export interface Assistantv2DialogNodeOutputOptionsElementValue {
  /**
   *
   * @type {Assistantv2MessageInput}
   * @memberof Assistantv2DialogNodeOutputOptionsElementValue
   */
  input?: Assistantv2MessageInput;
}

export function Assistantv2DialogNodeOutputOptionsElementValueFromJSON(
  json: any
): Assistantv2DialogNodeOutputOptionsElementValue {
  return Assistantv2DialogNodeOutputOptionsElementValueFromJSONTyped(
    json,
    false
  );
}

export function Assistantv2DialogNodeOutputOptionsElementValueFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Assistantv2DialogNodeOutputOptionsElementValue {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    input: !exists(json, "input")
      ? undefined
      : Assistantv2MessageInputFromJSON(json["input"]),
  };
}

export function Assistantv2DialogNodeOutputOptionsElementValueToJSON(
  value?: Assistantv2DialogNodeOutputOptionsElementValue | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    input: Assistantv2MessageInputToJSON(value.input),
  };
}
