/* tslint:disable */
/* eslint-disable */
/**
 * Alder Hey Chat API
 * This RESTful API provides an interface to the Alder Hey chatbot.
 *
 * The version of the OpenAPI document: abbb7b6
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  EchoHTTPError,
  EchoHTTPErrorFromJSON,
  EchoHTTPErrorToJSON,
  ViewmodelAddMessage,
  ViewmodelAddMessageFromJSON,
  ViewmodelAddMessageResponse,
  ViewmodelAddMessageResponseFromJSON,
  ViewmodelAddMessageResponseToJSON,
  ViewmodelAddMessageToJSON,
  ViewmodelPatchMessage,
  ViewmodelPatchMessageFromJSON,
  ViewmodelPatchMessageToJSON,
} from "../models";
import * as runtime from "../runtime";

export interface AddMessageRequest {
  conversationID: string;
  addMessage: ViewmodelAddMessage;
  aHTenantName?: string;
}

export interface PatchMessageRequest {
  conversationID: string;
  messageId: string;
  updateMessage: ViewmodelPatchMessage;
  aHTenantName?: string;
}

/**
 * no description
 */
export class MessagesApi extends runtime.BaseAPI {
  /**
   * Send a message to the Alder Hey chatbot within an existing conversation.
   * Add message to existing conversation
   */
  async addMessageRaw(
    requestParameters: AddMessageRequest
  ): Promise<runtime.ApiResponse<ViewmodelAddMessageResponse>> {
    if (
      requestParameters.conversationID === null ||
      requestParameters.conversationID === undefined
    ) {
      throw new runtime.RequiredError(
        "conversationID",
        "Required parameter requestParameters.conversationID was null or undefined when calling addMessage."
      );
    }

    if (
      requestParameters.addMessage === null ||
      requestParameters.addMessage === undefined
    ) {
      throw new runtime.RequiredError(
        "addMessage",
        "Required parameter requestParameters.addMessage was null or undefined when calling addMessage."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      requestParameters.aHTenantName !== undefined &&
      requestParameters.aHTenantName !== null
    ) {
      headerParameters["AH-Tenant-Name"] = String(
        requestParameters.aHTenantName
      );
    }

    const response = await this.request({
      path: `/conversations/{conversationID}/messages`.replace(
        `{${"conversationID"}}`,
        encodeURIComponent(String(requestParameters.conversationID))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ViewmodelAddMessageToJSON(requestParameters.addMessage),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ViewmodelAddMessageResponseFromJSON(jsonValue)
    );
  }

  /**
   * Send a message to the Alder Hey chatbot within an existing conversation.
   * Add message to existing conversation
   */
  async addMessage(
    requestParameters: AddMessageRequest
  ): Promise<ViewmodelAddMessageResponse> {
    const response = await this.addMessageRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update existing message to add comments or a user rating.
   * Update existing message
   */
  async patchMessageRaw(
    requestParameters: PatchMessageRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.conversationID === null ||
      requestParameters.conversationID === undefined
    ) {
      throw new runtime.RequiredError(
        "conversationID",
        "Required parameter requestParameters.conversationID was null or undefined when calling patchMessage."
      );
    }

    if (
      requestParameters.messageId === null ||
      requestParameters.messageId === undefined
    ) {
      throw new runtime.RequiredError(
        "messageId",
        "Required parameter requestParameters.messageId was null or undefined when calling patchMessage."
      );
    }

    if (
      requestParameters.updateMessage === null ||
      requestParameters.updateMessage === undefined
    ) {
      throw new runtime.RequiredError(
        "updateMessage",
        "Required parameter requestParameters.updateMessage was null or undefined when calling patchMessage."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      requestParameters.aHTenantName !== undefined &&
      requestParameters.aHTenantName !== null
    ) {
      headerParameters["AH-Tenant-Name"] = String(
        requestParameters.aHTenantName
      );
    }

    const response = await this.request({
      path: `/conversations/{conversationID}/messages/{messageId}`
        .replace(
          `{${"conversationID"}}`,
          encodeURIComponent(String(requestParameters.conversationID))
        )
        .replace(
          `{${"messageId"}}`,
          encodeURIComponent(String(requestParameters.messageId))
        ),
      method: "PATCH",
      headers: headerParameters,
      query: queryParameters,
      body: ViewmodelPatchMessageToJSON(requestParameters.updateMessage),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Update existing message to add comments or a user rating.
   * Update existing message
   */
  async patchMessage(requestParameters: PatchMessageRequest): Promise<void> {
    await this.patchMessageRaw(requestParameters);
  }
}
