/* tslint:disable */
/* eslint-disable */
/**
 * Alder Hey Chat API
 * This RESTful API provides an interface to the Alder Hey chatbot.
 *
 * The version of the OpenAPI document: abbb7b6
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  Assistantv2MessageContextGlobalSystem,
  Assistantv2MessageContextGlobalSystemFromJSON,
  Assistantv2MessageContextGlobalSystemFromJSONTyped,
  Assistantv2MessageContextGlobalSystemToJSON,
} from "./";

/**
 *
 * @export
 * @interface Assistantv2MessageContextGlobal
 */
export interface Assistantv2MessageContextGlobal {
  /**
   *
   * @type {Assistantv2MessageContextGlobalSystem}
   * @memberof Assistantv2MessageContextGlobal
   */
  system?: Assistantv2MessageContextGlobalSystem;
}

export function Assistantv2MessageContextGlobalFromJSON(
  json: any
): Assistantv2MessageContextGlobal {
  return Assistantv2MessageContextGlobalFromJSONTyped(json, false);
}

export function Assistantv2MessageContextGlobalFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Assistantv2MessageContextGlobal {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    system: !exists(json, "system")
      ? undefined
      : Assistantv2MessageContextGlobalSystemFromJSON(json["system"]),
  };
}

export function Assistantv2MessageContextGlobalToJSON(
  value?: Assistantv2MessageContextGlobal | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    system: Assistantv2MessageContextGlobalSystemToJSON(value.system),
  };
}
