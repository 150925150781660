import {
  Paper,
  Theme,
  Typography,
  alpha,
  darken,
  lighten,
  styled,
} from "@mui/material";
import React, { FunctionComponent } from "react";
import ReactMarkdown from "react-markdown";
import SafelySetInnerHTML from "safely-set-inner-html";

import { useAppContext } from "../AppContext";
import { MessageSide } from "../typings/chat_types";

const generateFirstBubbleStyling = (
  side: MessageSide,
  isFirstItem: boolean,
  theme: Theme,
  themeMode: string
) => {
  if (side === MessageSide.Right) {
    if (isFirstItem) {
      return {
        content: "''",
        width: 0,
        height: 0,
        borderLeft: `${theme.shape.borderRadius}px solid ${
          themeMode === "light"
            ? lighten(theme.palette.secondary.light, 0.44)
            : darken(theme.palette.secondary.light, 0.44)
        }`,
        borderRight: `${theme.shape.borderRadius + 8}px solid transparent`,
        borderTop: `${theme.shape.borderRadius}px solid ${
          themeMode === "light"
            ? lighten(theme.palette.secondary.light, 0.44)
            : darken(theme.palette.secondary.light, 0.44)
        }`,
        borderBottom: `${theme.shape.borderRadius - 6}px solid transparent`,
        right: `-${theme.shape.borderRadius + 1}px`,
        top: 0,
        position: "absolute",
      };
    } else
      return {
        content: undefined,
        width: undefined,
        height: undefined,
        borderLeft: undefined,
        borderRight: undefined,
        borderTop: undefined,
        borderBottom: undefined,
        right: undefined,
        top: undefined,
        position: undefined,
      };
  } else if (side === MessageSide.Left) {
    if (isFirstItem) {
      return {
        content: "''",
        width: 0,
        height: 0,
        position: "absolute",
        borderLeft: `${theme.shape.borderRadius + 8}px solid transparent`,
        borderRight: `${theme.shape.borderRadius}px solid ${
          themeMode === "light"
            ? lighten(theme.palette.primary.light, 0.44)
            : darken(theme.palette.primary.light, 0.44)
        }`,
        borderTop: `${theme.shape.borderRadius}px solid ${
          themeMode === "light"
            ? lighten(theme.palette.primary.light, 0.44)
            : darken(theme.palette.primary.light, 0.44)
        }`,
        borderBottom: `${theme.shape.borderRadius - 6}px solid transparent`,
        left: `-${theme.shape.borderRadius + 1}px`,
        top: 0,
      };
    } else
      return {
        content: undefined,
        width: undefined,
        height: undefined,
        borderLeft: undefined,
        borderRight: undefined,
        borderTop: undefined,
        borderBottom: undefined,
        left: undefined,
        top: undefined,
      };
  }
};

const safelySetInnerHTML = new SafelySetInnerHTML({
  ALLOWED_TAGS: ["a", "strong", "em", "b", "i"],
  ALLOWED_ATTRIBUTES: ["href", "class", "target", "rel"],
});

interface MessageTextProps {
  isFirstItem?: boolean;
  message?: string;
  side?: MessageSide;
  allowHTML?: boolean;
  themeMode?: string;
}
const MessageTextContainer = styled("div", {
  shouldForwardProp: prop => prop !== "side",
})<MessageTextProps>(({ theme, side }) => ({
  textAlign: side === MessageSide.Right ? "right" : "left",
  marginLeft: theme.spacing(1),
}));
const MessageTextBubble = styled(Paper, {
  shouldForwardProp: prop =>
    prop !== "side" && prop !== "isFirstItem" && prop !== "themeMode",
})<MessageTextProps>(({ theme, side, isFirstItem, themeMode }) => ({
  backgroundColor:
    side === MessageSide.Right
      ? alpha(theme.palette.secondary.main, 0.44)
      : alpha(theme.palette.primary.main, 0.44),
  position: "relative",
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius + 4,
  marginBottom: theme.spacing(1),
  display: "inline-block",
  wordBreak: "break-word",
  "& a": {
    color:
      side === MessageSide.Left ? theme.palette.secondary.light : undefined,
  },
  "&:before": generateFirstBubbleStyling(
    side!,
    isFirstItem!,
    theme,
    themeMode!
  ),
}));
const StyledMessageText = styled(Typography)<MessageTextProps>(() => ({
  display: "inline",
  whiteSpace: "pre-wrap",
  "& img": {
    maxWidth: "100%",
    heigth: "auto",
  },
}));

export const MessageText: FunctionComponent<MessageTextProps> = ({
  isFirstItem = false,
  // isLastItem = false,
  message,
  side,
  allowHTML,
  children,
}) => {
  const { colorType } = useAppContext();
  return (
    <MessageTextContainer side={side}>
      <MessageTextBubble
        side={side}
        isFirstItem={isFirstItem}
        elevation={0}
        themeMode={colorType}
      >
        {children}
        <StyledMessageText align="left">
          <ReactMarkdown>
            {allowHTML
              ? safelySetInnerHTML.transform(message!)?.toString()
              : message || ""}
          </ReactMarkdown>
        </StyledMessageText>
      </MessageTextBubble>
    </MessageTextContainer>
  );
};
