/* tslint:disable */
/* eslint-disable */
/**
 * Alder Hey Chat API
 * This RESTful API provides an interface to the Alder Hey chatbot.
 *
 * The version of the OpenAPI document: abbb7b6
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Assistantv2SearchResultMetadata
 */
export interface Assistantv2SearchResultMetadata {
  /**
   * The confidence score for the given result. For more information about how the confidence is calculated, see the Discovery service [documentation](../discovery#query-your-collection).
   * @type {number}
   * @memberof Assistantv2SearchResultMetadata
   */
  confidence?: number;
  /**
   * An unbounded measure of the relevance of a particular result, dependent on the query and matching document. A higher score indicates a greater match to the query parameters.
   * @type {number}
   * @memberof Assistantv2SearchResultMetadata
   */
  score?: number;
}

export function Assistantv2SearchResultMetadataFromJSON(
  json: any
): Assistantv2SearchResultMetadata {
  return Assistantv2SearchResultMetadataFromJSONTyped(json, false);
}

export function Assistantv2SearchResultMetadataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Assistantv2SearchResultMetadata {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    confidence: !exists(json, "confidence") ? undefined : json["confidence"],
    score: !exists(json, "score") ? undefined : json["score"],
  };
}

export function Assistantv2SearchResultMetadataToJSON(
  value?: Assistantv2SearchResultMetadata | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    confidence: value.confidence,
    score: value.score,
  };
}
