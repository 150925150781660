// import { Theme } from "@material-ui/core";

// declare module "@material-ui/core/styles/createMuiTheme" {
//   interface Theme {
//     customPalette?: {
//       messages?: {
//         user?: {
//           background?: string;
//           text?: string;
//         };
//         chatbot?: {
//           background?: string;
//           text?: string;
//         };
//       };
//       appBar?: {
//         textColour?: string;
//         backgroundColour?: string;
//         shadows?: boolean;
//       };
//     };
//   }

//   interface ThemeOptions {
//     customPalette?: {
//       messages?: {
//         user?: {
//           background?: string;
//           text?: string;
//         };
//         chatbot?: {
//           background?: string;
//           text?: string;
//         };
//       };
//       appBar?: {
//         textColour?: string;
//         backgroundColour?: string;
//         shadows?: boolean;
//       };
//     };
//   }
// }

// export default Theme;

import { PaletteMode } from "@mui/material";
import { orange } from "@mui/material/colors";

import { BrandTheme } from "../brands/Brand";

// declare module "@material-ui/core/styles/createBreakpoints" {
//   interface BreakpointOverrides {
//     xxs: true;
//   }
// }
declare module "@mui/material/styles" {
  interface Theme {
    tertiaryColor: {
      main: string;
      light: string;
      dark: string;
      contrastText: string;
    };
    disclaimerTitleIcon: {
      borderRadius: string;
      backgroundColor: string;
    };
    avatarSizes: {
      small: {
        width: number;
        height: number;
      };
      large: {
        width: number;
        height: number;
      };
    };
  }

  interface ThemeOptions {
    tertiaryColor?: {
      main?: string;
      light?: string;
      dark?: string;
      contrastText?: string;
    };
    disclaimerTitleIcon?: {
      borderRadius?: string;
      backgroundColor?: string;
    };
    avatarSizes?: {
      small?: {
        width?: number;
        height?: number;
      };
      large?: {
        width?: number;
        height?: number;
      };
    };
  }
}
const setTheme = (mode: PaletteMode, brandTheme?: BrandTheme) => ({
  // breakpoints: {
  //   values: {
  //     xxs: 0,
  //     xs: 360,
  //     sm: 600,
  //     md: 960,
  //     lg: 1280,
  //     xl: 1920,
  //   },
  // },
  palette: {
    ...(mode === "light"
      ? {
          background: {
            default: "#fff",
            paper: "#fff",
          },
          text: {
            primary: "rgba(0, 0, 0, 0.87)",
            secondary: "rgba(0, 0, 0, 0.6)",
            disabled: "rgba(0, 0, 0, 0.38)",
            hint: "rgba(0, 0, 0, 0.38)",
          },
          action: {
            active: "rgba(0, 0, 0, 0.54)",
            hover: "rgba(0, 0, 0, 0.04)",
            selected: "rgba(0, 0, 0, 0.08)",
            disabled: "rgba(0, 0, 0, 0.26)",
            disabledBackground: "rgba(0, 0, 0, 0.12)",
          },
          divider: "rgba(0, 0, 0, 0.12)",
        }
      : {
          background: {
            default: "#303030",
            paper: "#424242",
          },
          text: {
            primary: "#fff",
            secondary: "rgba(255, 255, 255, 0.7)",
            disabled: "rgba(255, 255, 255, 0.5)",
          },
          action: {
            active: "#fff",
            hover: "rgba(255, 255, 255, 0.08)",
            selected: "rgba(255, 255, 255, 0.16)",
            disabled: "rgba(255, 255, 255, 0.3)",
            disabledBackground: "rgba(255, 255, 255, 0.12)",
          },
          divider: "rgba(255, 255, 255, 0.12)",
        }),
    primary: {
      main: brandTheme?.primaryColour || "#ff5a5b",
    },
    secondary: {
      main: brandTheme?.secondaryColour || "#40bfd7",
    },
  },
  typography: {
    fontFamily: brandTheme?.typography?.fontFamily || "Montserrat",
    htmlFontSize: brandTheme?.typography?.htmlFontSize || 16,
    fontSize: brandTheme?.typography?.fontSize || 14,
    button: {
      fontSize: brandTheme?.typography?.button?.fontSize || "1em",
      fontFamily:
        brandTheme?.typography?.button?.fontSize || "Montserrat Alternates",
      fontWeight: brandTheme?.typography?.button?.fontWeight || 500,
      lineHeight: brandTheme?.typography?.button?.lineHeight || 1.75,
    },
    overline: {
      fontWeight: brandTheme?.typography?.overline?.fontWeight || 600,
      fontSize: brandTheme?.typography?.overline?.fontSize || "0.60em",
      fontFamily: brandTheme?.typography?.overline?.fontFamily || "Montserrat",
      lineHeight: brandTheme?.typography?.overline?.lineHeight || 2.5,
    },
    subtitle1: {
      fontSize: brandTheme?.typography?.subtitle1?.fontSize || "0.65em",
      fontWeight: brandTheme?.typography?.subtitle1?.fontWeight || 400,
      lineHeight: brandTheme?.typography?.subtitle1?.lineHeight || 1.4,
      fontFamily: brandTheme?.typography?.subtitle1?.fontFamily || "Montserrat",
    },
    h1: {
      fontSize: brandTheme?.typography?.h1?.fontSize || "5em",
      fontWeight: brandTheme?.typography?.h1?.fontWeight || 500,
      lineHeight: brandTheme?.typography?.h1?.lineHeight || 1.4,
      fontFamily: brandTheme?.typography?.h1?.fontFamily || "Montserrat",
    },
    h2: {
      fontSize: brandTheme?.typography?.h2?.fontSize || "3.75em",
      fontWeight: brandTheme?.typography?.h2?.fontWeight || 400,
      lineHeight: brandTheme?.typography?.h2?.lineHeight || 1.4,
      fontFamily: brandTheme?.typography?.h2?.fontFamily || "Montserrat",
    },
    h3: {
      fontSize: brandTheme?.typography?.h3?.fontSize || "3em",
      fontWeight: brandTheme?.typography?.h3?.fontWeight || 400,
      lineHeight: brandTheme?.typography?.h3?.lineHeight || 1.4,
      fontFamily: brandTheme?.typography?.h3?.fontFamily || "Montserrat",
    },
    h4: {
      fontSize: brandTheme?.typography?.h4?.fontSize || "2.125em",
      fontWeight: brandTheme?.typography?.h4?.fontWeight || 400,
      lineHeight: brandTheme?.typography?.h4?.lineHeight || 1.4,
      fontFamily: brandTheme?.typography?.h4?.fontFamily || "Montserrat",
    },
    h5: {
      fontSize: brandTheme?.typography?.h5?.fontSize || "1.5em",
      fontWeight: brandTheme?.typography?.h5?.fontWeight || 400,
      lineHeight: brandTheme?.typography?.h5?.lineHeight || 1.4,
      fontFamily: brandTheme?.typography?.h5?.fontFamily || "Montserrat",
    },
    h6: {
      fontSize: brandTheme?.typography?.h6?.fontSize || "1.25em",
      fontWeight: brandTheme?.typography?.h6?.fontWeight || 500,
      lineHeight: brandTheme?.typography?.h6?.lineHeight || 1.5,
      fontFamily: brandTheme?.typography?.h6?.fontFamily || "Montserrat",
    },
    caption: {
      fontSize: brandTheme?.typography?.caption?.fontSize || "0.75em",
      fontWeight: brandTheme?.typography?.caption?.fontWeight || 400,
      lineHeight: brandTheme?.typography?.caption?.lineHeight || 1.66,
      fontFamily: brandTheme?.typography?.caption?.fontFamily || "Montserrat",
    },
    body1: {
      fontSize: brandTheme?.typography?.body1?.fontSize || "1em",
      fontWeight: brandTheme?.typography?.body1?.fontWeight || 400,
      lineHeight: brandTheme?.typography?.body1?.lineHeight || 1.5,
      fontFamily: brandTheme?.typography?.body1?.fontFamily || "Montserrat",
    },
    body2: {
      fontSize: brandTheme?.typography?.body2?.fontSize || "0.875em",
      fontWeight: brandTheme?.typography?.body2?.fontWeight || 400,
      lineHeight: brandTheme?.typography?.body2?.lineHeight || 1.43,
      fontFamily: brandTheme?.typography?.body2?.fontFamily || "Montserrat",
    },
  },
  components: {
    MuiSwitch: {
      styleOverrides: {
        root: {
          width:
            brandTheme?.components?.MuiSwitch?.styleOverrides?.root?.width ||
            42,
          height:
            brandTheme?.components?.MuiSwitch?.styleOverrides?.root?.height ||
            26,
          padding:
            brandTheme?.components?.MuiSwitch?.styleOverrides?.root?.padding ||
            0,
          margin:
            brandTheme?.components?.MuiSwitch?.styleOverrides?.root?.margin ||
            8,
        },
        switchBase: {
          padding:
            brandTheme?.components?.MuiSwitch?.styleOverrides?.switchBase
              ?.padding || 1,
          "&$checked, &$colorPrimary$checked, &$colorSecondary$checked": {
            transform:
              brandTheme?.components?.MuiSwitch?.styleOverrides?.switchBase?.[
                "&$checked, &$colorPrimary$checked, &$colorSecondary$checked"
              ].transform || "translateX(16px)",
            color:
              brandTheme?.components?.MuiSwitch?.styleOverrides?.switchBase?.[
                "&$checked, &$colorPrimary$checked, &$colorSecondary$checked"
              ].color || "#fff",
            "& + $track": {
              opacity:
                brandTheme?.components?.MuiSwitch?.styleOverrides?.switchBase?.[
                  "&$checked, &$colorPrimary$checked, &$colorSecondary$checked"
                ]["& + $track"].opacity || 1,
              border:
                brandTheme?.components?.MuiSwitch?.styleOverrides?.switchBase?.[
                  "&$checked, &$colorPrimary$checked, &$colorSecondary$checked"
                ]["& + $track"].border || "none",
            },
          },
        },
        thumb: {
          width:
            brandTheme?.components?.MuiSwitch?.styleOverrides?.thumb?.width ||
            24,
          height:
            brandTheme?.components?.MuiSwitch?.styleOverrides?.thumb?.height ||
            24,
        },
        track: {
          borderRadius:
            brandTheme?.components?.MuiSwitch?.styleOverrides?.track
              ?.borderRadius || 13,
          border:
            brandTheme?.components?.MuiSwitch?.styleOverrides?.track?.border ||
            "1px solid #bdbdbd",
          backgroundColor:
            brandTheme?.components?.MuiSwitch?.styleOverrides?.track
              ?.backgroundColor || "#fafafa",
          opacity:
            brandTheme?.components?.MuiSwitch?.styleOverrides?.track?.opacity ||
            1,
          transition:
            brandTheme?.components?.MuiSwitch?.styleOverrides?.track
              ?.transition ||
            "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
      },
    },
    MuiAppBar: {
      // root: {
      //   color: '#fff',
      //   backgroundColor: '#40bfd7',
      // },
      styleOverrides: {
        colorPrimary: {
          color:
            brandTheme?.components?.MuiAppBar?.styleOverrides?.colorPrimary
              ?.color || "#fff",
          backgroundColor:
            brandTheme?.components?.MuiAppBar?.styleOverrides?.colorPrimary
              ?.backgroundColor || "#40bfd7",
        },
      },
    },
  },
  spacing: brandTheme?.spacing || 6,
  shape: {
    borderRadius: brandTheme?.shape?.borderRadius || 8,
  },
  tertiaryColor: {
    main: brandTheme?.tertiaryColor?.main || "#003088",
    light: brandTheme?.tertiaryColor?.light || "#33599F",
    dark: brandTheme?.tertiaryColor?.dark || "#00215F",
    contrastText: brandTheme?.tertiaryColor?.contrastText || "#fff",
  },
  disclaimerTitleIcon: {
    borderRadius: brandTheme?.disclaimerTitleIcon?.borderRadius || "50%",
    backgroundColor:
      brandTheme?.disclaimerTitleIcon?.backgroundColor || orange[500],
  },
  avatarSizes: {
    small: {
      width: brandTheme?.avatarSizes?.small?.width || 24,
      height: brandTheme?.avatarSizes?.small?.height || 24,
    },
    large: {
      width: brandTheme?.avatarSizes?.large?.width || 80,
      height: brandTheme?.avatarSizes?.large?.height || 80,
    },
  },
});

// const lightTheme:  AugmentedTheme = createTheme({
//     palette: {
//       primary: {
//         main: '#ff5a5b',
//       },
//       secondary: {
//         main: '#40bfd7',
//       },
//     },
//     typography: {
//       fontFamily: 'Montserrat',
//       htmlFontSize: 16,
//       button: {
//         fontSize: '1em',
//         fontFamily: 'Montserrat Alternates',
//       },
//       overline: {
//         fontWeight: 600,
//         fontSize: '0.75em',
//       },
//       h1: {
//         fontSize: '5em',
//         fontWeight: 500,
//         lineHeight: 1.4,
//       },
//       subtitle1: {
//         fontSize: '0.65em',
//       },
//       fontSize: 16,
//     },
//     overrides: {
//       MuiSwitch: {
//         root: {
//           width: 42,
//           height: 26,
//           padding: 0,
//           margin: 8,
//         },
//         switchBase: {
//           padding: 1,
//           '&$checked, &$colorPrimary$checked, &$colorSecondary$checked': {
//             transform: 'translateX(16px)',
//             color: '#fff',
//             '& + $track': {
//               opacity: 1,
//               border: 'none',
//             },
//           },
//         },
//         thumb: {
//           width: 24,
//           height: 24,
//         },
//         track: {
//           borderRadius: 13,
//           border: '1px solid #bdbdbd',
//           backgroundColor: '#fafafa',
//           opacity: 1,
//           transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
//         },
//       },
//       MuiAppBar: {
//         // root: {
//         //   color: '#fff',
//         //   backgroundColor: '#40bfd7',
//         // },
//         colorPrimary: {
//           color: '#fff',
//           backgroundColor: '#40bfd7',
//         },
//       },
//     },
//     spacing: 6,
//     shape: {
//       borderRadius: 4,
//     },
//     disclaimerTitleIcon: {
//       borderRadius: '50%',
//       backgroundColor: orange[500],
//     },
//   });

export default setTheme;
