import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Link,
  Paper,
  Typography,
  styled,
} from "@mui/material";
import React, { FunctionComponent, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAppContext } from "../AppContext";
import { useBrand } from "../brands/Brand";
import { Session } from "../helpers/Session";

interface StyledGridProps {
  isFullscreen: boolean;
}
const StyledGrid = styled(Grid, {
  shouldForwardProp: prop => prop !== "isFullscreen",
})<StyledGridProps>(({ theme, isFullscreen }) => ({
  padding: theme.spacing(2),
  height: isFullscreen ? "100%" : "auto",
}));
const AvatarContainer = styled(Avatar)(({ theme }) => ({
  width: theme.avatarSizes.large.width,
  height: theme.avatarSizes.large.height,
  backgroundColor: theme.palette.background.paper,
}));
const AvatarImage = styled("img")(() => ({
  width: "100%",
  height: "100%",
}));

export const Welcome: FunctionComponent = () => {
  const { colorType, isFullscreen } = useAppContext();
  const navigate = useNavigate();
  const brand = useBrand();

  useEffect(() => {
    if (Session.getInstance().hasValidConversation()) {
      navigate("/chat");
    }
  }, [navigate]);

  return (
    <StyledGrid
      isFullscreen={isFullscreen}
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      wrap="nowrap"
    >
      <Grid
        item
        sm={isFullscreen ? 9 : undefined}
        md={isFullscreen ? 6 : undefined}
      >
        <Paper elevation={4}>
          <Card>
            <CardHeader
              sx={{ paddingBottom: 0, marginLeft: theme => theme.spacing(2) }}
              avatar={
                <AvatarContainer
                  aria-label="warning"
                  variant={
                    brand.welcome.titleIcon.round ? "circular" : "square"
                  }
                >
                  {colorType === "light" ? (
                    typeof brand.welcome.titleIcon.iconLight === "string" ? (
                      <AvatarImage
                        src={brand.welcome.titleIcon.iconLight}
                        alt={brand.welcome.titleIcon.alt}
                      />
                    ) : (
                      <brand.welcome.titleIcon.iconLight
                        titleAccess={brand.welcome.titleIcon.alt ?? ""}
                      />
                    )
                  ) : typeof brand.welcome.titleIcon.iconDark === "string" ? (
                    <AvatarImage
                      src={brand.welcome.titleIcon.iconDark}
                      alt={brand.welcome.titleIcon.alt}
                    />
                  ) : (
                    <brand.welcome.titleIcon.iconDark
                      titleAccess={brand.welcome.titleIcon.alt ?? ""}
                    />
                  )}
                </AvatarContainer>
              }
              title={
                <Typography variant="h6">{brand.welcome.title}</Typography>
              }
            />
            <CardContent>
              <Typography
                component="p"
                variant="overline"
                color="secondary"
                align="center"
              >
                {brand.welcome.info.purposeStatement.text}
              </Typography>
              {brand.welcome.info.termsOfUseStatements.map(
                (termsOfUse, index) => (
                  <Typography component="p" key={index}>
                    <Box component="span" fontWeight="fontWeightMedium">
                      {index + 1}. {termsOfUse.summaryInBold}
                    </Box>
                    : {termsOfUse.details}
                  </Typography>
                )
              )}
            </CardContent>

            <CardActions sx={{ justifyContent: "center" }}>
              <Button
                onClick={() => navigate("/chat")}
                color="primary"
                variant="contained"
              >
                I understand, ask questions
              </Button>
            </CardActions>
          </Card>
        </Paper>
      </Grid>
      <Grid item>
        {brand.welcome.courtesy && (
          <Typography>
            <Link href={brand.welcome.courtesy.link}>
              {brand.welcome.courtesy.text}
            </Link>
          </Typography>
        )}
      </Grid>
    </StyledGrid>
  );
};
