/* tslint:disable */
/* eslint-disable */
/**
 * Alder Hey Chat API
 * This RESTful API provides an interface to the Alder Hey chatbot.
 *
 * The version of the OpenAPI document: abbb7b6
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ViewmodelPatchMessage
 */
export interface ViewmodelPatchMessage {
  /**
   *
   * @type {string}
   * @memberof ViewmodelPatchMessage
   */
  comments?: string;
  /**
   *
   * @type {number}
   * @memberof ViewmodelPatchMessage
   */
  score?: number;
}

export function ViewmodelPatchMessageFromJSON(
  json: any
): ViewmodelPatchMessage {
  return ViewmodelPatchMessageFromJSONTyped(json, false);
}

export function ViewmodelPatchMessageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ViewmodelPatchMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    comments: !exists(json, "comments") ? undefined : json["comments"],
    score: !exists(json, "score") ? undefined : json["score"],
  };
}

export function ViewmodelPatchMessageToJSON(
  value?: ViewmodelPatchMessage | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    comments: value.comments,
    score: value.score,
  };
}
