// Hartree Hatter Brand

import React from "react";

import HartreeHatterAvatar from "../static/hartree-hatter.png";
import HartreeCentreLogo from "../static/UKRI_STFC_HARTREE_CENTRE.svg";
import WelcomeGifDark from "../static/welcome_gif_dark.gif";
import WelcomeGifLight from "../static/welcome_gif_light.gif";
import { Brand, BrandTheme } from "./Brand";

const appName = "Hartree Hatter";

const hartreeHatterTheme: BrandTheme = {
  primaryColour: "#ff5a5b",
  secondaryColour: "#40bfd7",
};

export const hartreeHatterBrand: Brand = {
  botName: "Hartree Hatter",
  appName,
  provider: "Hartree Centre",
  ui: {
    images: {
      chatAvatar: HartreeHatterAvatar,
      appBarImage: HartreeCentreLogo,
    },
    embedded: {
      globalFontSize: 16,
    },
    showAboutLink: true,
    showMessageRating: false,
  },
  welcome: {
    titleIcon: {
      iconLight: WelcomeGifLight,
      iconDark: WelcomeGifDark,
      alt: "Hartree Centre Logo",
      round: false,
    },
    // titleIcon: defaults.welcome.titleIcon,
    title: "Welcome to Daresbury Laboratory Open Week! ",
    info: {
      purposeStatement: {
        text: "For use by visitors of the Daresbury Laboratory Open Week event.",
      },
      termsOfUseStatements: [
        {
          summaryInBold: "Anonimity & Data Use",
          details:
            "Your questions are anonymous, but stored for training our chatbot.",
        },
        {
          summaryInBold: "Avoid Personal Details",
          details:
            "Do not share personal identification details. The chatbot is not designed to handle sensitive information.",
        },
        {
          summaryInBold: "Limitations",
          details:
            "The chatbot does not provide personal advice or guarantee the accuracy of all information.",
        },
      ],
    },
    courtesy: {
      text: "Courtesy of Hartree Centre",
      link: "https://www.hartree.stfc.ac.uk/",
    },
  },
  about: {
    termsOfUseStatementsExpanded: [
      `"Hartree Hatter" is a chatbot designed to provide visitors with general information
            about the Daresbury Laboratory Open Week event and the Hartree Centre.`,

      "It does not provide personal advice.",

      `By using the service you confirm that you understand the limitations of the
            information provided.`,
    ],
    questions: [
      "What is the Daresbury Laboratory Open Week event?",
      "What is the Hartree Centre?",
      "What is the Hartree Hatter?",
      "What is a chatbot?",
      "Tell me a dad joke",
    ],
    escalation: (
      <span>
        “{appName}” is a computer programme which cannot answer questions
        specific to a particular user or their individual circumstances. If you
        need more personalized advice or information, please reach out to one of
        our staff members present at the event.
      </span>
    ),
  },
  theme: hartreeHatterTheme,
  enableSurveys: false,
};

export {};
