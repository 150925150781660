/* tslint:disable */
/* eslint-disable */
/**
 * Alder Hey Chat API
 * This RESTful API provides an interface to the Alder Hey chatbot.
 *
 * The version of the OpenAPI document: abbb7b6
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Assistantv2DialogLogMessage
 */
export interface Assistantv2DialogLogMessage {
  /**
   * The severity of the log message.
   * @type {string}
   * @memberof Assistantv2DialogLogMessage
   */
  level: string;
  /**
   * The text of the log message.
   * @type {string}
   * @memberof Assistantv2DialogLogMessage
   */
  message: string;
}

export function Assistantv2DialogLogMessageFromJSON(
  json: any
): Assistantv2DialogLogMessage {
  return Assistantv2DialogLogMessageFromJSONTyped(json, false);
}

export function Assistantv2DialogLogMessageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Assistantv2DialogLogMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    level: json["level"],
    message: json["message"],
  };
}

export function Assistantv2DialogLogMessageToJSON(
  value?: Assistantv2DialogLogMessage | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    level: value.level,
    message: value.message,
  };
}
