/* tslint:disable */
/* eslint-disable */
/**
 * Alder Hey Chat API
 * This RESTful API provides an interface to the Alder Hey chatbot.
 *
 * The version of the OpenAPI document: abbb7b6
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Assistantv2RuntimeEntityInterpretation
 */
export interface Assistantv2RuntimeEntityInterpretation {
  /**
   * The calendar used to represent a recognized date (for example, `Gregorian`).
   * @type {string}
   * @memberof Assistantv2RuntimeEntityInterpretation
   */
  calendarType?: string;
  /**
   * A unique identifier used to associate a recognized time and date. If the user input contains a date and time that are mentioned together (for example, `Today at 5`, the same **datetime_link** value is returned for both the `@sys-date` and `@sys-time` entities).
   * @type {string}
   * @memberof Assistantv2RuntimeEntityInterpretation
   */
  datetimeLink?: string;
  /**
   * A locale-specific holiday name (such as `thanksgiving` or `christmas`). This property is included when a `@sys-date` entity is recognized based on a holiday name in the user input.
   * @type {string}
   * @memberof Assistantv2RuntimeEntityInterpretation
   */
  festival?: string;
  /**
   * The precision or duration of a time range specified by a recognized `@sys-time` or `@sys-date` entity.
   * @type {string}
   * @memberof Assistantv2RuntimeEntityInterpretation
   */
  granularity?: string;
  /**
   * A recognized numeric value, represented as an integer or double.
   * @type {number}
   * @memberof Assistantv2RuntimeEntityInterpretation
   */
  numericValue?: number;
  /**
   * A recognized term for a time that was mentioned as a part of the day in the user\'s input (for example, `morning` or `afternoon`).
   * @type {string}
   * @memberof Assistantv2RuntimeEntityInterpretation
   */
  partOfDay?: string;
  /**
   * A unique identifier used to associate multiple recognized `@sys-date`, `@sys-time`, or `@sys-number` entities that are recognized as a range of values in the user\'s input (for example, `from July 4 until July 14` or `from 20 to 25`).
   * @type {string}
   * @memberof Assistantv2RuntimeEntityInterpretation
   */
  rangeLink?: string;
  /**
   * The word in the user input that indicates that a `sys-date` or `sys-time` entity is part of an implied range where only one date or time is specified (for example, `since` or `until`).
   * @type {string}
   * @memberof Assistantv2RuntimeEntityInterpretation
   */
  rangeModifier?: string;
  /**
   * A recognized mention of a relative day, represented numerically as an offset from the current date (for example, `-1` for `yesterday` or `10` for `in ten days`).
   * @type {number}
   * @memberof Assistantv2RuntimeEntityInterpretation
   */
  relativeDay?: number;
  /**
   * A recognized mention of a relative hour, represented numerically as an offset from the current hour (for example, `3` for `in three hours` or `-1` for `an hour ago`).
   * @type {number}
   * @memberof Assistantv2RuntimeEntityInterpretation
   */
  relativeHour?: number;
  /**
   * A recognized mention of a relative time, represented numerically as an offset in minutes from the current time (for example, `5` for `in five minutes` or `-15` for `fifteen minutes ago`).
   * @type {number}
   * @memberof Assistantv2RuntimeEntityInterpretation
   */
  relativeMinute?: number;
  /**
   * A recognized mention of a relative month, represented numerically as an offset from the current month (for example, `1` for `next month` or `-3` for `three months ago`).
   * @type {number}
   * @memberof Assistantv2RuntimeEntityInterpretation
   */
  relativeMonth?: number;
  /**
   * A recognized mention of a relative time, represented numerically as an offset in seconds from the current time (for example, `10` for `in ten seconds` or `-30` for `thirty seconds ago`).
   * @type {number}
   * @memberof Assistantv2RuntimeEntityInterpretation
   */
  relativeSecond?: number;
  /**
   * A recognized mention of a relative week, represented numerically as an offset from the current week (for example, `2` for `in two weeks` or `-1` for `last week).
   * @type {number}
   * @memberof Assistantv2RuntimeEntityInterpretation
   */
  relativeWeek?: number;
  /**
   * A recognized mention of a relative date range for a weekend, represented numerically as an offset from the current weekend (for example, `0` for `this weekend` or `-1` for `last weekend`).
   * @type {number}
   * @memberof Assistantv2RuntimeEntityInterpretation
   */
  relativeWeekend?: number;
  /**
   * A recognized mention of a relative year, represented numerically as an offset from the current year (for example, `1` for `next year` or `-5` for `five years ago`).
   * @type {number}
   * @memberof Assistantv2RuntimeEntityInterpretation
   */
  relativeYear?: number;
  /**
   * A recognized mention of a specific date, represented numerically as the date within the month (for example, `30` for `June 30`.).
   * @type {number}
   * @memberof Assistantv2RuntimeEntityInterpretation
   */
  specificDay?: number;
  /**
   * A recognized mention of a specific day of the week as a lowercase string (for example, `monday`).
   * @type {string}
   * @memberof Assistantv2RuntimeEntityInterpretation
   */
  specificDayOfWeek?: string;
  /**
   * A recognized specific hour mentioned as part of a time value (for example, `10` for `10:15 AM`.).
   * @type {number}
   * @memberof Assistantv2RuntimeEntityInterpretation
   */
  specificHour?: number;
  /**
   * A recognized specific minute mentioned as part of a time value (for example, `15` for `10:15 AM`.).
   * @type {number}
   * @memberof Assistantv2RuntimeEntityInterpretation
   */
  specificMinute?: number;
  /**
   * A recognized mention of a specific month, represented numerically (for example, `7` for `July`).
   * @type {number}
   * @memberof Assistantv2RuntimeEntityInterpretation
   */
  specificMonth?: number;
  /**
   * A recognized mention of a specific quarter, represented numerically (for example, `3` for `the third quarter`).
   * @type {number}
   * @memberof Assistantv2RuntimeEntityInterpretation
   */
  specificQuarter?: number;
  /**
   * A recognized specific second mentioned as part of a time value (for example, `30` for `10:15:30 AM`.).
   * @type {number}
   * @memberof Assistantv2RuntimeEntityInterpretation
   */
  specificSecond?: number;
  /**
   * A recognized mention of a specific year (for example, `2016`).
   * @type {number}
   * @memberof Assistantv2RuntimeEntityInterpretation
   */
  specificYear?: number;
  /**
   * The type of numeric value recognized in the user input (`integer` or `rational`).
   * @type {string}
   * @memberof Assistantv2RuntimeEntityInterpretation
   */
  subtype?: string;
  /**
   * A recognized time zone mentioned as part of a time value (for example, `EST`).
   * @type {string}
   * @memberof Assistantv2RuntimeEntityInterpretation
   */
  timezone?: string;
}

export function Assistantv2RuntimeEntityInterpretationFromJSON(
  json: any
): Assistantv2RuntimeEntityInterpretation {
  return Assistantv2RuntimeEntityInterpretationFromJSONTyped(json, false);
}

export function Assistantv2RuntimeEntityInterpretationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Assistantv2RuntimeEntityInterpretation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    calendarType: !exists(json, "calendar_type")
      ? undefined
      : json["calendar_type"],
    datetimeLink: !exists(json, "datetime_link")
      ? undefined
      : json["datetime_link"],
    festival: !exists(json, "festival") ? undefined : json["festival"],
    granularity: !exists(json, "granularity") ? undefined : json["granularity"],
    numericValue: !exists(json, "numeric_value")
      ? undefined
      : json["numeric_value"],
    partOfDay: !exists(json, "part_of_day") ? undefined : json["part_of_day"],
    rangeLink: !exists(json, "range_link") ? undefined : json["range_link"],
    rangeModifier: !exists(json, "range_modifier")
      ? undefined
      : json["range_modifier"],
    relativeDay: !exists(json, "relative_day")
      ? undefined
      : json["relative_day"],
    relativeHour: !exists(json, "relative_hour")
      ? undefined
      : json["relative_hour"],
    relativeMinute: !exists(json, "relative_minute")
      ? undefined
      : json["relative_minute"],
    relativeMonth: !exists(json, "relative_month")
      ? undefined
      : json["relative_month"],
    relativeSecond: !exists(json, "relative_second")
      ? undefined
      : json["relative_second"],
    relativeWeek: !exists(json, "relative_week")
      ? undefined
      : json["relative_week"],
    relativeWeekend: !exists(json, "relative_weekend")
      ? undefined
      : json["relative_weekend"],
    relativeYear: !exists(json, "relative_year")
      ? undefined
      : json["relative_year"],
    specificDay: !exists(json, "specific_day")
      ? undefined
      : json["specific_day"],
    specificDayOfWeek: !exists(json, "specific_day_of_week")
      ? undefined
      : json["specific_day_of_week"],
    specificHour: !exists(json, "specific_hour")
      ? undefined
      : json["specific_hour"],
    specificMinute: !exists(json, "specific_minute")
      ? undefined
      : json["specific_minute"],
    specificMonth: !exists(json, "specific_month")
      ? undefined
      : json["specific_month"],
    specificQuarter: !exists(json, "specific_quarter")
      ? undefined
      : json["specific_quarter"],
    specificSecond: !exists(json, "specific_second")
      ? undefined
      : json["specific_second"],
    specificYear: !exists(json, "specific_year")
      ? undefined
      : json["specific_year"],
    subtype: !exists(json, "subtype") ? undefined : json["subtype"],
    timezone: !exists(json, "timezone") ? undefined : json["timezone"],
  };
}

export function Assistantv2RuntimeEntityInterpretationToJSON(
  value?: Assistantv2RuntimeEntityInterpretation | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    calendar_type: value.calendarType,
    datetime_link: value.datetimeLink,
    festival: value.festival,
    granularity: value.granularity,
    numeric_value: value.numericValue,
    part_of_day: value.partOfDay,
    range_link: value.rangeLink,
    range_modifier: value.rangeModifier,
    relative_day: value.relativeDay,
    relative_hour: value.relativeHour,
    relative_minute: value.relativeMinute,
    relative_month: value.relativeMonth,
    relative_second: value.relativeSecond,
    relative_week: value.relativeWeek,
    relative_weekend: value.relativeWeekend,
    relative_year: value.relativeYear,
    specific_day: value.specificDay,
    specific_day_of_week: value.specificDayOfWeek,
    specific_hour: value.specificHour,
    specific_minute: value.specificMinute,
    specific_month: value.specificMonth,
    specific_quarter: value.specificQuarter,
    specific_second: value.specificSecond,
    specific_year: value.specificYear,
    subtype: value.subtype,
    timezone: value.timezone,
  };
}
