/* tslint:disable */
/* eslint-disable */
/**
 * Alder Hey Chat API
 * This RESTful API provides an interface to the Alder Hey chatbot.
 *
 * The version of the OpenAPI document: abbb7b6
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ViewmodelAddConversationResponse
 */
export interface ViewmodelAddConversationResponse {
  /**
   *
   * @type {number}
   * @memberof ViewmodelAddConversationResponse
   */
  conversationTimeoutMinutes?: number;
  /**
   * When the Cloudant depdendency has gone, this should be changed to `int`.
   * @type {string}
   * @memberof ViewmodelAddConversationResponse
   */
  id?: string;
}

export function ViewmodelAddConversationResponseFromJSON(
  json: any
): ViewmodelAddConversationResponse {
  return ViewmodelAddConversationResponseFromJSONTyped(json, false);
}

export function ViewmodelAddConversationResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ViewmodelAddConversationResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    conversationTimeoutMinutes: !exists(json, "conversationTimeoutMinutes")
      ? undefined
      : json["conversationTimeoutMinutes"],
    id: !exists(json, "id") ? undefined : json["id"],
  };
}

export function ViewmodelAddConversationResponseToJSON(
  value?: ViewmodelAddConversationResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    conversationTimeoutMinutes: value.conversationTimeoutMinutes,
    id: value.id,
  };
}
