import {
  AssistantRuntimeResponseGeneric,
  Assistantv2DialogNodeOutputOptionsElement,
  Assistantv2MessageInput,
  ViewmodelAddMessageInteractionEnum as InteractionType,
  ViewmodelAddConversationResponse,
  ViewmodelAddMessage,
} from "../api_client";

export enum MessageAuthor {
  User,
  Chatbot,
}

export interface UserMessageInfo extends ViewmodelAddMessage {
  author: MessageAuthor.User;
}

export interface ChatbotMessageInfo extends AssistantRuntimeResponseGeneric {
  author: MessageAuthor.Chatbot;
  disabled: boolean; // Only used for options selection
  turnId?: string;
  rating?: number;
  interaction?: InteractionType;
  count?: number;
}

export enum MessageSide {
  Left,
  Right,
}

export type MessageInfo = UserMessageInfo | ChatbotMessageInfo;
export type ChatbotMessageOptionInfo =
  Assistantv2DialogNodeOutputOptionsElement;
export type ConversationInfo = ViewmodelAddConversationResponse;
export type UserMessageInput = Assistantv2MessageInput;
