/* tslint:disable */
/* eslint-disable */
/**
 * Alder Hey Chat API
 * This RESTful API provides an interface to the Alder Hey chatbot.
 *
 * The version of the OpenAPI document: abbb7b6
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  Assistantv2CaptureGroup,
  Assistantv2CaptureGroupFromJSON,
  Assistantv2CaptureGroupFromJSONTyped,
  Assistantv2CaptureGroupToJSON,
  Assistantv2RuntimeEntityAlternative,
  Assistantv2RuntimeEntityAlternativeFromJSON,
  Assistantv2RuntimeEntityAlternativeFromJSONTyped,
  Assistantv2RuntimeEntityAlternativeToJSON,
  Assistantv2RuntimeEntityInterpretation,
  Assistantv2RuntimeEntityInterpretationFromJSON,
  Assistantv2RuntimeEntityInterpretationFromJSONTyped,
  Assistantv2RuntimeEntityInterpretationToJSON,
  Assistantv2RuntimeEntityRole,
  Assistantv2RuntimeEntityRoleFromJSON,
  Assistantv2RuntimeEntityRoleFromJSONTyped,
  Assistantv2RuntimeEntityRoleToJSON,
} from "./";

/**
 *
 * @export
 * @interface Assistantv2RuntimeEntity
 */
export interface Assistantv2RuntimeEntity {
  /**
   * An array of possible alternative values that the user might have intended instead of the value returned in the **value** property. This property is returned only for `@sys-time` and `@sys-date` entities when the user\'s input is ambiguous.  This property is included only if the new system entities are enabled for the skill.
   * @type {Array<Assistantv2RuntimeEntityAlternative>}
   * @memberof Assistantv2RuntimeEntity
   */
  alternatives?: Array<Assistantv2RuntimeEntityAlternative>;
  /**
   * A decimal percentage that represents Watson\'s confidence in the recognized entity.
   * @type {number}
   * @memberof Assistantv2RuntimeEntity
   */
  confidence?: number;
  /**
   * An entity detected in the input.
   * @type {string}
   * @memberof Assistantv2RuntimeEntity
   */
  entity: string;
  /**
   * The recognized capture groups for the entity, as defined by the entity pattern.
   * @type {Array<Assistantv2CaptureGroup>}
   * @memberof Assistantv2RuntimeEntity
   */
  groups?: Array<Assistantv2CaptureGroup>;
  /**
   *
   * @type {Assistantv2RuntimeEntityInterpretation}
   * @memberof Assistantv2RuntimeEntity
   */
  interpretation?: Assistantv2RuntimeEntityInterpretation;
  /**
   * An array of zero-based character offsets that indicate where the detected entity values begin and end in the input text.
   * @type {Array<number>}
   * @memberof Assistantv2RuntimeEntity
   */
  location: Array<number>;
  /**
   * Any metadata for the entity.
   * @type {object}
   * @memberof Assistantv2RuntimeEntity
   */
  metadata?: object;
  /**
   *
   * @type {Assistantv2RuntimeEntityRole}
   * @memberof Assistantv2RuntimeEntity
   */
  role?: Assistantv2RuntimeEntityRole;
  /**
   * The term in the input text that was recognized as an entity value.
   * @type {string}
   * @memberof Assistantv2RuntimeEntity
   */
  value: string;
}

export function Assistantv2RuntimeEntityFromJSON(
  json: any
): Assistantv2RuntimeEntity {
  return Assistantv2RuntimeEntityFromJSONTyped(json, false);
}

export function Assistantv2RuntimeEntityFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Assistantv2RuntimeEntity {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    alternatives: !exists(json, "alternatives")
      ? undefined
      : (json["alternatives"] as Array<any>).map(
          Assistantv2RuntimeEntityAlternativeFromJSON
        ),
    confidence: !exists(json, "confidence") ? undefined : json["confidence"],
    entity: json["entity"],
    groups: !exists(json, "groups")
      ? undefined
      : (json["groups"] as Array<any>).map(Assistantv2CaptureGroupFromJSON),
    interpretation: !exists(json, "interpretation")
      ? undefined
      : Assistantv2RuntimeEntityInterpretationFromJSON(json["interpretation"]),
    location: json["location"],
    metadata: !exists(json, "metadata") ? undefined : json["metadata"],
    role: !exists(json, "role")
      ? undefined
      : Assistantv2RuntimeEntityRoleFromJSON(json["role"]),
    value: json["value"],
  };
}

export function Assistantv2RuntimeEntityToJSON(
  value?: Assistantv2RuntimeEntity | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    alternatives:
      value.alternatives === undefined
        ? undefined
        : (value.alternatives as Array<any>).map(
            Assistantv2RuntimeEntityAlternativeToJSON
          ),
    confidence: value.confidence,
    entity: value.entity,
    groups:
      value.groups === undefined
        ? undefined
        : (value.groups as Array<any>).map(Assistantv2CaptureGroupToJSON),
    interpretation: Assistantv2RuntimeEntityInterpretationToJSON(
      value.interpretation
    ),
    location: value.location,
    metadata: value.metadata,
    role: Assistantv2RuntimeEntityRoleToJSON(value.role),
    value: value.value,
  };
}
