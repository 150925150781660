/* tslint:disable */
/* eslint-disable */
/**
 * Alder Hey Chat API
 * This RESTful API provides an interface to the Alder Hey chatbot.
 *
 * The version of the OpenAPI document: abbb7b6
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  Assistantv2DialogNodeOutputOptionsElementValue,
  Assistantv2DialogNodeOutputOptionsElementValueFromJSON,
  Assistantv2DialogNodeOutputOptionsElementValueFromJSONTyped,
  Assistantv2DialogNodeOutputOptionsElementValueToJSON,
} from "./";

/**
 *
 * @export
 * @interface Assistantv2DialogNodeOutputOptionsElement
 */
export interface Assistantv2DialogNodeOutputOptionsElement {
  /**
   * The user-facing label for the option.
   * @type {string}
   * @memberof Assistantv2DialogNodeOutputOptionsElement
   */
  label: string;
  /**
   *
   * @type {Assistantv2DialogNodeOutputOptionsElementValue}
   * @memberof Assistantv2DialogNodeOutputOptionsElement
   */
  value: Assistantv2DialogNodeOutputOptionsElementValue;
}

export function Assistantv2DialogNodeOutputOptionsElementFromJSON(
  json: any
): Assistantv2DialogNodeOutputOptionsElement {
  return Assistantv2DialogNodeOutputOptionsElementFromJSONTyped(json, false);
}

export function Assistantv2DialogNodeOutputOptionsElementFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Assistantv2DialogNodeOutputOptionsElement {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    label: json["label"],
    value: Assistantv2DialogNodeOutputOptionsElementValueFromJSON(
      json["value"]
    ),
  };
}

export function Assistantv2DialogNodeOutputOptionsElementToJSON(
  value?: Assistantv2DialogNodeOutputOptionsElement | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    label: value.label,
    value: Assistantv2DialogNodeOutputOptionsElementValueToJSON(value.value),
  };
}
