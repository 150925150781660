import { constants } from "../helpers/Constants";
import { ConversationsApi, MessagesApi, SurveysApi } from "./apis";
import { Configuration } from "./runtime";

export * from "./runtime";
export * from "./apis";
export * from "./models";

export const getConversationsAPI = () =>
  new ConversationsApi(new Configuration({ basePath: constants.api.baseUrl }));

export const getMessagesAPI = () =>
  new MessagesApi(new Configuration({ basePath: constants.api.baseUrl }));

export const getSurveysAPI = () =>
  new SurveysApi(new Configuration({ basePath: constants.api.baseUrl }));
