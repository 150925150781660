/* tslint:disable */
/* eslint-disable */
/**
 * Alder Hey Chat API
 * This RESTful API provides an interface to the Alder Hey chatbot.
 *
 * The version of the OpenAPI document: abbb7b6
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  Assistantv2DialogLogMessage,
  Assistantv2DialogLogMessageFromJSON,
  Assistantv2DialogLogMessageFromJSONTyped,
  Assistantv2DialogLogMessageToJSON,
  Assistantv2DialogNodesVisited,
  Assistantv2DialogNodesVisitedFromJSON,
  Assistantv2DialogNodesVisitedFromJSONTyped,
  Assistantv2DialogNodesVisitedToJSON,
} from "./";

/**
 *
 * @export
 * @interface Assistantv2MessageOutputDebug
 */
export interface Assistantv2MessageOutputDebug {
  /**
   * Assistant sets this to true when this message response concludes or interrupts a dialog.
   * @type {boolean}
   * @memberof Assistantv2MessageOutputDebug
   */
  branchExited?: boolean;
  /**
   * When `branch_exited` is set to `true` by the Assistant, the `branch_exited_reason` specifies whether the dialog completed by itself or got interrupted.
   * @type {string}
   * @memberof Assistantv2MessageOutputDebug
   */
  branchExitedReason?: string;
  /**
   * An array of up to 50 messages logged with the request.
   * @type {Array<Assistantv2DialogLogMessage>}
   * @memberof Assistantv2MessageOutputDebug
   */
  logMessages?: Array<Assistantv2DialogLogMessage>;
  /**
   * An array of objects containing detailed diagnostic information about the nodes that were triggered during processing of the input message.
   * @type {Array<Assistantv2DialogNodesVisited>}
   * @memberof Assistantv2MessageOutputDebug
   */
  nodesVisited?: Array<Assistantv2DialogNodesVisited>;
}

export function Assistantv2MessageOutputDebugFromJSON(
  json: any
): Assistantv2MessageOutputDebug {
  return Assistantv2MessageOutputDebugFromJSONTyped(json, false);
}

export function Assistantv2MessageOutputDebugFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Assistantv2MessageOutputDebug {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    branchExited: !exists(json, "branch_exited")
      ? undefined
      : json["branch_exited"],
    branchExitedReason: !exists(json, "branch_exited_reason")
      ? undefined
      : json["branch_exited_reason"],
    logMessages: !exists(json, "log_messages")
      ? undefined
      : (json["log_messages"] as Array<any>).map(
          Assistantv2DialogLogMessageFromJSON
        ),
    nodesVisited: !exists(json, "nodes_visited")
      ? undefined
      : (json["nodes_visited"] as Array<any>).map(
          Assistantv2DialogNodesVisitedFromJSON
        ),
  };
}

export function Assistantv2MessageOutputDebugToJSON(
  value?: Assistantv2MessageOutputDebug | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    branch_exited: value.branchExited,
    branch_exited_reason: value.branchExitedReason,
    log_messages:
      value.logMessages === undefined
        ? undefined
        : (value.logMessages as Array<any>).map(
            Assistantv2DialogLogMessageToJSON
          ),
    nodes_visited:
      value.nodesVisited === undefined
        ? undefined
        : (value.nodesVisited as Array<any>).map(
            Assistantv2DialogNodesVisitedToJSON
          ),
  };
}
