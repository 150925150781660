const paths = {
  welcome: "/",
  about: "/about",
  chat: "/chat",
  survey: "/survey",
};

declare const RUNTIME_CONFIG: { baseUrl: string };

const api = {
  // If value from environment is nullish, API will take value from generated runtime.ts
  // BASE_PATH instead.
  baseUrl: RUNTIME_CONFIG.baseUrl ?? process.env.REACT_APP_API_URL,
};

export const constants = {
  api,
  paths,

  isDevelopment: process.env.NODE_ENV === "development",
  version: process.env.REACT_APP_VERSION ?? "dev",
  environment: process.env.REACT_APP_TENANT ?? "unknown",
  enableUserId: process.env.REACT_APP_ENABLE_USER_ID === "true",
};
