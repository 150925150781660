import {
  Button,
  ButtonGroup,
  Paper,
  Typography,
  darken,
  lighten,
  styled,
} from "@mui/material";
import React, { useState } from "react";

import { useAppContext } from "../AppContext";
import { MessageSide } from "../typings/chat_types";

interface MessageRowProps {
  side: MessageSide;
  themeMode?: string;
}

const MessageRow = styled("div", {
  shouldForwardProp: prop => prop !== "side",
})<MessageRowProps>(({ side }) => ({
  textAlign: side === MessageSide.Left ? "left" : "right",
}));
const MessageContainer = styled(Paper, {
  shouldForwardProp: prop => prop !== "side" && prop !== "themeMode",
})<MessageRowProps>(({ theme, side, themeMode }) => ({
  padding: theme.spacing(1),
  display: "inline-block",
  wordBreak: "break-word",
  textAlign: "center",
  borderRadius: theme.shape.borderRadius + 4,
  backgroundColor:
    side === MessageSide.Left
      ? themeMode === "light"
        ? lighten(theme.palette.primary.light, 0.44)
        : darken(theme.palette.primary.light, 0.44)
      : themeMode === "light"
      ? lighten(theme.palette.secondary.light, 0.44)
      : darken(theme.palette.secondary.light, 0.44),
  "&:before": {
    content: side === MessageSide.Right ? "''" : undefined,
    width: 0,
    height: 0,
    borderLeft:
      side === MessageSide.Right
        ? `${theme.shape.borderRadius}px solid ${
            themeMode === "light"
              ? lighten(theme.palette.secondary.light, 0.44)
              : darken(theme.palette.secondary.light, 0.44)
          }`
        : undefined,
    borderRight:
      side === MessageSide.Right
        ? `${theme.shape.borderRadius + 8}px solid transparent`
        : undefined,
    borderTop:
      side === MessageSide.Right
        ? `${theme.shape.borderRadius}px solid ${
            themeMode === "light"
              ? lighten(theme.palette.secondary.light, 0.44)
              : darken(theme.palette.secondary.light, 0.44)
          }`
        : undefined,
    borderBottom:
      side === MessageSide.Right
        ? `${theme.shape.borderRadius - 6}px solid transparent`
        : undefined,
    right:
      side === MessageSide.Right
        ? `-${theme.shape.borderRadius + 1}px`
        : undefined,
    top: 0,
    position: "absolute",
  },
}));
const StyledButtonGroup = styled(ButtonGroup)(() => ({
  display: "inline-flex",
  flexDirection: "column",
}));

export interface ChatbotOptionInfo<T> {
  label: string;
  value: T;
}

export interface ChatbotMessageOptionsProps<T> {
  title?: string;
  description?: string;
  disabled: boolean;
  options: ChatbotOptionInfo<T>[];
  side: MessageSide;
  onOptionSelected: (selection: T) => void;
}

// I can't find a way of specifying a generic FunctionComponent here, so just falling
// back to specifying the props type manually.
// The `<T, >` is needed because the usual `<T>` is interpreted as a React element.
export const ChatbotMessageOptions = <T,>({
  title,
  description,
  disabled,
  options,
  side,
  onOptionSelected,
}: ChatbotMessageOptionsProps<T>) => {
  const { colorType } = useAppContext();
  const [selectedOption, setSelectedOption] = useState<number | undefined>(
    undefined
  );
  const [optionButtonsDisabled, setOptionButtonsDisabled] = useState(false);

  return (
    <MessageRow side={side}>
      <MessageContainer elevation={0} side={side} themeMode={colorType}>
        {title && (
          <Typography variant="h5" gutterBottom>
            {title}
          </Typography>
        )}
        {description && <Typography>{description}</Typography>}
        <StyledButtonGroup
          orientation="vertical"
          disabled={disabled}
          fullWidth={true}
          aria-label="vertical contained button group"
          variant="text"
        >
          {options.map((option, index) => (
            <Button
              key={index}
              disabled={optionButtonsDisabled}
              sx={{
                backgroundColor:
                  selectedOption === index
                    ? colorType === "light"
                      ? theme => lighten(theme.palette.secondary.main, 0.25)
                      : theme => darken(theme.palette.secondary.main, 0.25)
                    : "transparent",
                color:
                  selectedOption === index
                    ? theme => theme.palette.text.primary
                    : theme => theme.palette.text.secondary,
              }}
              onClick={() => {
                onOptionSelected(option.value);
                setOptionButtonsDisabled(true);
                setSelectedOption(index);
              }}
            >
              {option.label}
            </Button>
          ))}
        </StyledButtonGroup>
      </MessageContainer>
    </MessageRow>
  );
};
