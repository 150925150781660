/* tslint:disable */
/* eslint-disable */
/**
 * Alder Hey Chat API
 * This RESTful API provides an interface to the Alder Hey chatbot.
 *
 * The version of the OpenAPI document: abbb7b6
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Assistantv2RuntimeEntityAlternative
 */
export interface Assistantv2RuntimeEntityAlternative {
  /**
   * A decimal percentage that represents Watson\'s confidence in the recognized entity.
   * @type {number}
   * @memberof Assistantv2RuntimeEntityAlternative
   */
  confidence?: number;
  /**
   * The entity value that was recognized in the user input.
   * @type {string}
   * @memberof Assistantv2RuntimeEntityAlternative
   */
  value?: string;
}

export function Assistantv2RuntimeEntityAlternativeFromJSON(
  json: any
): Assistantv2RuntimeEntityAlternative {
  return Assistantv2RuntimeEntityAlternativeFromJSONTyped(json, false);
}

export function Assistantv2RuntimeEntityAlternativeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Assistantv2RuntimeEntityAlternative {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    confidence: !exists(json, "confidence") ? undefined : json["confidence"],
    value: !exists(json, "value") ? undefined : json["value"],
  };
}

export function Assistantv2RuntimeEntityAlternativeToJSON(
  value?: Assistantv2RuntimeEntityAlternative | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    confidence: value.confidence,
    value: value.value,
  };
}
