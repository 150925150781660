/* tslint:disable */
/* eslint-disable */
/**
 * Alder Hey Chat API
 * This RESTful API provides an interface to the Alder Hey chatbot.
 *
 * The version of the OpenAPI document: abbb7b6
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Assistantv2CaptureGroup
 */
export interface Assistantv2CaptureGroup {
  /**
   * A recognized capture group for the entity.
   * @type {string}
   * @memberof Assistantv2CaptureGroup
   */
  group: string;
  /**
   * Zero-based character offsets that indicate where the entity value begins and ends in the input text.
   * @type {Array<number>}
   * @memberof Assistantv2CaptureGroup
   */
  location?: Array<number>;
}

export function Assistantv2CaptureGroupFromJSON(
  json: any
): Assistantv2CaptureGroup {
  return Assistantv2CaptureGroupFromJSONTyped(json, false);
}

export function Assistantv2CaptureGroupFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Assistantv2CaptureGroup {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    group: json["group"],
    location: !exists(json, "location") ? undefined : json["location"],
  };
}

export function Assistantv2CaptureGroupToJSON(
  value?: Assistantv2CaptureGroup | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    group: value.group,
    location: value.location,
  };
}
