import { styled } from "@mui/material";
import React, { FunctionComponent } from "react";

import { MessageSide } from "../typings/chat_types";
import { MessageText } from "./MessageText";

interface TypingIndicatorProps {
  side: MessageSide;
}
const MessageContainer = styled(MessageText)(() => ({
  position: "absolute",
  left: 5,
  top: -13,
}));
const DotGroup = styled("div")(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  height: theme.spacing(2),
}));
const StyledDot = styled("div")<TypingIndicatorProps>(({ theme, side }) => ({
  borderRadius: "100%",
  display: "inline-block",
  width: 6,
  height: 6,
  marginRight: 2,
  WebkitAnimation: "TypingIndicatorDotAnimation 1.5s infinite ease-in-out",
  animation: "TypingIndicatorDotAnimation 1.5s infinite ease-in-out",
  "&:nth-of-type(1)": {
    animationDelay: "200ms",
    WebkitAnimationDelay: "200ms",
  },
  "&:nth-of-type(2)": {
    animationDelay: "300ms",
    WebkitAnimationDelay: "300ms",
  },
  "&:nth-of-type(3)": {
    animationDelay: "400ms",
    WebkitAnimationDelay: "400ms",
  },
  backgroundColor:
    side === MessageSide.Left
      ? theme.palette.secondary.contrastText
      : theme.palette.primary.contrastText,
}));

export const TypingIndicator: FunctionComponent<TypingIndicatorProps> = ({
  side,
}) => {
  return (
    <MessageContainer side={side} message="" allowHTML={false}>
      <div>
        <DotGroup>
          <StyledDot side={side} />
          <StyledDot side={side} />
          <StyledDot side={side} />
        </DotGroup>
      </div>
    </MessageContainer>
  );
};
