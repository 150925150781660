import {
  HelpOutline as AboutIcon,
  ArrowBack as BackIcon,
  Brightness2TwoTone,
  Brightness5Sharp,
  ExitToApp as CloseIcon,
  Fullscreen,
  FullscreenExit,
  Minimize as MinimiseIcon,
  FeedbackOutlined as SurveyIcon,
} from "@mui/icons-material";
import { IconButton, Tooltip, styled } from "@mui/material";
import AppBar, { AppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useAppContext } from "../AppContext";
import { useBrand } from "../brands/Brand";
import { constants } from "../helpers/Constants";
import { Session } from "../helpers/Session";

interface AppTopBarProps {
  title: string;
  gridArea: string;
}
interface StyledAppBarProps extends AppBarProps {
  isFullscreen: boolean;
  gridArea: string;
}
const StyledAppBar = styled(AppBar, {
  shouldForwardProp: prop => prop !== "isFullscreen" && prop !== "gridArea",
})<StyledAppBarProps>(({ theme, isFullscreen, gridArea }) => ({
  zIndex: theme.zIndex.appBar,
  transition: isFullscreen ? undefined : "border 0.5s ease-in-out",
  borderRadius: isFullscreen ? 0 : "20px 20px 0 0",
  gridArea: gridArea || undefined,
}));

const StyledTitleLogo = styled("img")(() => ({
  marginLeft: 0,
  width: "auto",
  height: 48,
  padding: 6,
}));

const StyledActionButtons = styled("div")(() => ({
  display: "flex",
  justifyContent: "flex-end",
  textAlign: "right",
  width: "100%",
}));

export const AppTopBar: FunctionComponent<AppTopBarProps> = ({
  title,
  gridArea,
}) => {
  const {
    isWidget,
    isDialogShowing,
    isFullscreen,
    toggleFullScreen,
    colorType,
    toggleColorType,
  } = useAppContext();
  // const classes = useStyles({ isFullscreen: isFullscreen, gridArea: gridArea });
  const brand = useBrand();
  const navigate = useNavigate();
  const locationPath = useLocation().pathname;

  const [screenDimensions, setScreenDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [forcedMaximised, setForcedMaximised] = useState(false);

  useEffect(() => {
    const retrieveScreenSize = () =>
      setScreenDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });

    window.addEventListener("resize", retrieveScreenSize);
    return () => window.removeEventListener("resize", retrieveScreenSize);
  });
  const buttonSize = "medium";

  // If screen is too small, there's not enough room to render the widget so go straight to full-screen.
  const minScreenSizeForWidget = {
    width: 465,
    height: 732,
  };
  const isScreenBigEnoughForWidget =
    screenDimensions.width > minScreenSizeForWidget.width &&
    screenDimensions.height > minScreenSizeForWidget.height;

  const shouldShowBack =
    !isDialogShowing &&
    (locationPath === constants.paths.about ||
      locationPath === constants.paths.survey);
  const shouldShowSurvey =
    !isDialogShowing &&
    brand.enableSurveys &&
    locationPath === constants.paths.chat;
  const shouldShowHelp =
    !isDialogShowing &&
    brand.ui.showAboutLink &&
    locationPath === constants.paths.chat;
  const shouldShowExit =
    !isDialogShowing && locationPath === constants.paths.chat;
  const shouldShowFullscreen = isWidget && isScreenBigEnoughForWidget;
  const shouldShowMinimise = isWidget;

  useEffect(() => {
    if (!isScreenBigEnoughForWidget && !isFullscreen) {
      // Our screen is too small for the widget to be displayed but we're not maximised yet, so do it now.
      setForcedMaximised(true);
      toggleFullScreen();
      window.alderHeyChat?.toggleFullscreen();
    } else if (isScreenBigEnoughForWidget && isFullscreen && forcedMaximised) {
      // We had to force-maximise because the screen got too small but now it's bigger so we can un-maximise.
      setForcedMaximised(false);
      toggleFullScreen();
      window.alderHeyChat?.toggleFullscreen();
    }
  }, [
    isScreenBigEnoughForWidget,
    isFullscreen,
    toggleFullScreen,
    forcedMaximised,
  ]);

  return (
    <StyledAppBar
      gridArea={gridArea}
      isFullscreen={isFullscreen}
      position="fixed"
    >
      <Toolbar variant="dense" disableGutters={!isFullscreen}>
        {shouldShowBack && (
          <div>
            <Tooltip title="Back">
              <IconButton
                aria-label="back"
                onClick={() => navigate(-1)}
                color="inherit"
              >
                <BackIcon />
              </IconButton>
            </Tooltip>
          </div>
        )}

        {brand.ui.images.appBarImage && (
          <StyledTitleLogo
            src={brand.ui.images.appBarImage}
            alt={brand.botName}
          />
        )}

        {!brand.ui.images.appBarImage && (
          <Typography variant="h6" sx={{ zIndex: "-1" }} component="div">
            {title}
          </Typography>
        )}

        <StyledActionButtons>
          <Tooltip
            title={
              colorType === "light" ? "Toggle Dark Theme" : "Toggle Light Theme"
            }
          >
            <IconButton onClick={toggleColorType} color="inherit">
              {colorType === "light" ? (
                <Brightness5Sharp />
              ) : (
                <Brightness2TwoTone />
              )}
            </IconButton>
          </Tooltip>
          {shouldShowHelp && (
            <Tooltip title="About">
              <IconButton
                aria-label="about"
                color="inherit"
                size={buttonSize}
                onClick={() => navigate(constants.paths.about)}
              >
                <AboutIcon />
              </IconButton>
            </Tooltip>
          )}

          {shouldShowSurvey && (
            <Tooltip title="Feedback">
              <IconButton
                onClick={() => navigate(constants.paths.survey)}
                aria-label="feedback"
                color="inherit"
                size={buttonSize}
              >
                <SurveyIcon />
              </IconButton>
            </Tooltip>
          )}

          {shouldShowExit && (
            <Tooltip title="Log Out">
              <IconButton
                aria-label="log out"
                onClick={() => {
                  Session.getInstance().clearConversation();
                  navigate("/");
                }}
                color="inherit"
                size={buttonSize}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          )}

          {shouldShowFullscreen && (
            <Tooltip title="Toggle fullscreen">
              <IconButton
                aria-label="toggle fullscreen"
                onClick={() => {
                  if (window.alderHeyChat === undefined) {
                    console.warn(
                      "[ah-chat] Unable to find the global widget extensions - are you definitely running in Widget mode?"
                    );
                    return;
                  }

                  toggleFullScreen();
                  window.alderHeyChat.toggleFullscreen();
                }}
                color="inherit"
                size={buttonSize}
              >
                {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
              </IconButton>
            </Tooltip>
          )}

          {shouldShowMinimise && (
            <Tooltip title="Minimise">
              <IconButton
                aria-label="minimise"
                onClick={() => {
                  if (window.alderHeyChat === undefined) {
                    console.warn(
                      "[ah-chat] Unable to find the global widget extensions - are you definitely running in Widget mode?"
                    );
                    return;
                  }

                  window.alderHeyChat.minimise();
                }}
                color="inherit"
                size={buttonSize}
              >
                <MinimiseIcon />
              </IconButton>
            </Tooltip>
          )}
        </StyledActionButtons>
      </Toolbar>
    </StyledAppBar>
  );
};
