import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material/SvgIcon/SvgIcon";
import React from "react";

import { hartreeHatterBrand } from "./HartreeHatter";
import { unbranded } from "./Unbranded";

export interface BrandTheme {
  primaryColour?: string;
  secondaryColour?: string;
  typography?: {
    fontSize?: number;
    fontFamily?: string;
    htmlFontSize?: number;
    button: {
      fontSize?: string;
      fontFamily?: string;
      fontWeight?: number;
      lineHeight?: number;
    };
    overline: {
      fontSize?: string;
      fontFamily?: string;
      fontWeight?: number;
      lineHeight?: number;
    };
    subtitle1: {
      fontSize?: string;
      fontFamily?: string;
      fontWeight?: number;
      lineHeight?: number;
    };
    subtitle2: {
      fontSize?: string;
      fontFamily?: string;
      fontWeight?: number;
      lineHeight?: number;
    };
    body1: {
      fontSize?: string;
      fontFamily?: string;
      fontWeight?: number;
      lineHeight?: number;
    };
    body2: {
      fontSize?: string;
      fontFamily?: string;
      fontWeight?: number;
      lineHeight?: number;
    };
    caption: {
      fontSize?: string;
      fontFamily?: string;
      fontWeight?: number;
      lineHeight?: number;
    };
    h1: {
      fontSize?: string;
      fontFamily?: string;
      fontWeight?: number;
      lineHeight?: number;
    };
    h2: {
      fontSize?: string;
      fontFamily?: string;
      fontWeight?: number;
      lineHeight?: number;
    };
    h3: {
      fontSize?: string;
      fontFamily?: string;
      fontWeight?: number;
      lineHeight?: number;
    };
    h4: {
      fontSize?: string;
      fontFamily?: string;
      fontWeight?: number;
      lineHeight?: number;
    };
    h5: {
      fontSize?: string;
      fontFamily?: string;
      fontWeight?: number;
      lineHeight?: number;
    };
    h6: {
      fontSize?: string;
      fontFamily?: string;
      fontWeight?: number;
      lineHeight?: number;
    };
  };
  components?: {
    MuiSwitch?: {
      styleOverrides?: {
        root?: {
          width?: number;
          height?: number;
          padding?: number;
          margin?: number;
        };
        switchBase?: {
          padding?: number;
          "&$checked, &$colorPrimary$checked, &$colorSecondary$checked": {
            transform: string;
            color: string;
            "& + $track": {
              opacity: number;
              border: string;
            };
          };
        };
        thumb?: {
          width?: number;
          height?: number;
        };
        track?: {
          borderRadius?: number;
          border?: string;
          backgroundColor?: string;
          opacity?: number;
          transition?: string;
        };
      };
    };
    MuiAppBar?: {
      styleOverrides?: {
        colorPrimary?: {
          color?: string;
          backgroundColor?: string;
        };
      };
    };
  };
  // default is 8
  spacing?: number;
  shape?: {
    borderRadius?: number;
  };
  tertiaryColor?: {
    main?: string;
    light?: string;
    dark?: string;
    contrastText?: string;
  };
  disclaimerTitleIcon?: {
    borderRadius?: string;
    backgroundColor?: string;
  };
  avatarSizes?: {
    small?: {
      width?: number;
      height?: number;
    };
    large?: {
      width?: number;
      height?: number;
    };
  };
}

export interface Brand {
  botName: string;
  appName: string;
  provider: string;
  ui: {
    images: {
      chatAvatar?: string;
      appBarImage?: string;
    };
    embedded?: {
      // Set font-size material UI expects (Default: 16)
      globalFontSize?: number;
    };
    showAboutLink: boolean;
    showMessageRating: boolean;
  };
  welcome: {
    titleIcon: {
      iconLight: string | OverridableComponent<SvgIconTypeMap<object, "svg">>;
      iconDark: string | OverridableComponent<SvgIconTypeMap<object, "svg">>;
      // Optional alt text for icon/image
      alt?: string;
      round?: boolean;
    };
    title: string;
    info: {
      purposeStatement: {
        text: string;
        isBold?: boolean;
      };
      termsOfUseStatements: {
        summaryInBold: string;
        details: string;
      }[];
    };
    courtesy?: { text: string; link: string };
  };
  about: {
    termsOfUseStatementsExpanded: string[];
    questions: string[];
    escalation: React.JSX.Element;
  };
  search?: {
    openLinkText?: string;
  };
  theme?: BrandTheme;
  enableSurveys: boolean;
}

const brands: { [brand: string]: Brand } = {
  unbranded,
  hartreeHatter: hartreeHatterBrand,
};

const defaultBrand = brands.unbranded;
const envBrand = process.env.REACT_APP_BRAND;

// Provide env brand given that is set and is a vaild brand else we give the default brand.
// TODO: rename this as this is not a React hook and as per convention, only hooks should start with "use".
export const useBrand = () =>
  envBrand && brands[envBrand] ? brands[envBrand] : defaultBrand;
