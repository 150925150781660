/* tslint:disable */
/* eslint-disable */
/**
 * Alder Hey Chat API
 * This RESTful API provides an interface to the Alder Hey chatbot.
 *
 * The version of the OpenAPI document: abbb7b6
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  Assistantv2SearchResultMetadata,
  Assistantv2SearchResultMetadataFromJSON,
  Assistantv2SearchResultMetadataFromJSONTyped,
  Assistantv2SearchResultMetadataToJSON,
} from "./";

/**
 *
 * @export
 * @interface Assistantv2SearchResult
 */
export interface Assistantv2SearchResult {
  /**
   * A description of the search result. This is taken from an abstract, summary, or highlight field in the Discovery service response, as specified in the search skill configuration.
   * @type {string}
   * @memberof Assistantv2SearchResult
   */
  body?: string;
  /**
   *
   * @type {object}
   * @memberof Assistantv2SearchResult
   */
  highlight?: object;
  /**
   * The unique identifier of the document in the Discovery service collection.  This property is included in responses from search skills, which are a beta feature available only to Plus or Premium plan users.
   * @type {string}
   * @memberof Assistantv2SearchResult
   */
  id: string;
  /**
   *
   * @type {Assistantv2SearchResultMetadata}
   * @memberof Assistantv2SearchResult
   */
  resultMetadata: Assistantv2SearchResultMetadata;
  /**
   * The title of the search result. This is taken from a title or name field in the Discovery service response, as specified in the search skill configuration.
   * @type {string}
   * @memberof Assistantv2SearchResult
   */
  title?: string;
  /**
   * The URL of the original data object in its native data source.
   * @type {string}
   * @memberof Assistantv2SearchResult
   */
  url?: string;
}

export function Assistantv2SearchResultFromJSON(
  json: any
): Assistantv2SearchResult {
  return Assistantv2SearchResultFromJSONTyped(json, false);
}

export function Assistantv2SearchResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Assistantv2SearchResult {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    body: !exists(json, "body") ? undefined : json["body"],
    highlight: !exists(json, "highlight") ? undefined : json["highlight"],
    id: json["id"],
    resultMetadata: Assistantv2SearchResultMetadataFromJSON(
      json["result_metadata"]
    ),
    title: !exists(json, "title") ? undefined : json["title"],
    url: !exists(json, "url") ? undefined : json["url"],
  };
}

export function Assistantv2SearchResultToJSON(
  value?: Assistantv2SearchResult | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    body: value.body,
    highlight: value.highlight,
    id: value.id,
    result_metadata: Assistantv2SearchResultMetadataToJSON(
      value.resultMetadata
    ),
    title: value.title,
    url: value.url,
  };
}
