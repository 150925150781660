/* tslint:disable */
/* eslint-disable */
/**
 * Alder Hey Chat API
 * This RESTful API provides an interface to the Alder Hey chatbot.
 *
 * The version of the OpenAPI document: abbb7b6
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Assistantv2MessageInputOptions
 */
export interface Assistantv2MessageInputOptions {
  /**
   * Whether to return more than one intent. Set to `true` to return all matching intents.
   * @type {boolean}
   * @memberof Assistantv2MessageInputOptions
   */
  alternateIntents?: boolean;
  /**
   * Whether to return additional diagnostic information. Set to `true` to return additional information in the `output.debug` property. If you also specify **return_context**=`true`, the returned skill context includes the `system.state` property.
   * @type {boolean}
   * @memberof Assistantv2MessageInputOptions
   */
  debug?: boolean;
  /**
   * Whether to return session context, including full conversation state. If you specify `true`, the response includes the `context` property, and the skill context includes the `system.state` property.  **Note:** If **export**=`true`, the context is returned regardless of the value of **return_context**.
   * @type {boolean}
   * @memberof Assistantv2MessageInputOptions
   */
  _export?: boolean;
  /**
   * Whether to restart dialog processing at the root of the dialog, regardless of any previously visited nodes. **Note:** This does not affect `turn_count` or any other context variables.
   * @type {boolean}
   * @memberof Assistantv2MessageInputOptions
   */
  restart?: boolean;
  /**
   * Whether to return session context with the response. If you specify `true`, the response includes the `context` property. If you also specify **debug**=`true`, the returned skill context includes the `system.state` property.
   * @type {boolean}
   * @memberof Assistantv2MessageInputOptions
   */
  returnContext?: boolean;
}

export function Assistantv2MessageInputOptionsFromJSON(
  json: any
): Assistantv2MessageInputOptions {
  return Assistantv2MessageInputOptionsFromJSONTyped(json, false);
}

export function Assistantv2MessageInputOptionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Assistantv2MessageInputOptions {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    alternateIntents: !exists(json, "alternate_intents")
      ? undefined
      : json["alternate_intents"],
    debug: !exists(json, "debug") ? undefined : json["debug"],
    _export: !exists(json, "export") ? undefined : json["export"],
    restart: !exists(json, "restart") ? undefined : json["restart"],
    returnContext: !exists(json, "return_context")
      ? undefined
      : json["return_context"],
  };
}

export function Assistantv2MessageInputOptionsToJSON(
  value?: Assistantv2MessageInputOptions | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    alternate_intents: value.alternateIntents,
    debug: value.debug,
    export: value._export,
    restart: value.restart,
    return_context: value.returnContext,
  };
}
