/* tslint:disable */
/* eslint-disable */
/**
 * Alder Hey Chat API
 * This RESTful API provides an interface to the Alder Hey chatbot.
 *
 * The version of the OpenAPI document: abbb7b6
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  AssistantMessageInput,
  AssistantMessageInputFromJSON,
  AssistantMessageInputFromJSONTyped,
  AssistantMessageInputToJSON,
  AssistantMessageResponse,
  AssistantMessageResponseFromJSON,
  AssistantMessageResponseFromJSONTyped,
  AssistantMessageResponseToJSON,
} from "./";

/**
 *
 * @export
 * @interface ViewmodelAddMessageResponse
 */
export interface ViewmodelAddMessageResponse {
  /**
   *
   * @type {AssistantMessageResponse}
   * @memberof ViewmodelAddMessageResponse
   */
  assistantResponse?: AssistantMessageResponse;
  /**
   *
   * @type {string}
   * @memberof ViewmodelAddMessageResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ViewmodelAddMessageResponse
   */
  interaction?: ViewmodelAddMessageResponseInteractionEnum;
  /**
   *
   * @type {AssistantMessageInput}
   * @memberof ViewmodelAddMessageResponse
   */
  request?: AssistantMessageInput;
}

export function ViewmodelAddMessageResponseFromJSON(
  json: any
): ViewmodelAddMessageResponse {
  return ViewmodelAddMessageResponseFromJSONTyped(json, false);
}

export function ViewmodelAddMessageResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ViewmodelAddMessageResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    assistantResponse: !exists(json, "assistantResponse")
      ? undefined
      : AssistantMessageResponseFromJSON(json["assistantResponse"]),
    id: !exists(json, "id") ? undefined : json["id"],
    interaction: !exists(json, "interaction") ? undefined : json["interaction"],
    request: !exists(json, "request")
      ? undefined
      : AssistantMessageInputFromJSON(json["request"]),
  };
}

export function ViewmodelAddMessageResponseToJSON(
  value?: ViewmodelAddMessageResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    assistantResponse: AssistantMessageResponseToJSON(value.assistantResponse),
    id: value.id,
    interaction: value.interaction,
    request: AssistantMessageInputToJSON(value.request),
  };
}

/**
 * @export
 * @enum {string}
 */
export enum ViewmodelAddMessageResponseInteractionEnum {
  TextInput = "text_input",
  OptionSelection = "option_selection",
  Autoprompt = "autoprompt",
}
