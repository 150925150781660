import { Rating } from "@mui/lab";
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  ViewmodelAddSurveyRecommendEnum as SurveyRecommendation,
  getSurveysAPI,
} from "../api_client";
import { useAppContext } from "../AppContext";
import { useBrand } from "../brands/Brand";
import { ScrollToTopOnMount } from "../components/ScrollToTopOnMount";
import { Session } from "../helpers/Session";

interface FormQuestionProps {
  inputName: string;
  value: number | null;
  setValue: (newValue: number | null) => void;
  questionText: string;
  error?: string;
  clearError: () => void;
}

const FormQuestion: FunctionComponent<FormQuestionProps> = ({
  questionText,
  inputName,
  value,
  setValue,
  error,
  clearError,
}) => {
  return (
    <FormFieldContainer error={error !== undefined}>
      <FormQuestionContainer>
        <FormQuestionLabel>
          <Typography variant="h6" component="div" gutterBottom>
            {questionText}
            <Typography
              component="span"
              sx={{ color: theme => theme.palette.error.main }}
            >
              *
            </Typography>
          </Typography>
        </FormQuestionLabel>
        <Rating
          name={inputName}
          value={value}
          size="large"
          aria-required="true"
          aria-describedby={`${inputName}-helper-text`}
          onChange={(_event, newValue) => {
            setValue(newValue);
            clearError();
          }}
        />
      </FormQuestionContainer>
      <QuestionError id={`${inputName}-helper-text`}>
        {error && <FormHelperText error={true}>{error}</FormHelperText>}
      </QuestionError>
    </FormFieldContainer>
  );
};

enum SurveyStateMachineState {
  FillingOut,
  Submitting,
  SuccessfullySubmitted,
}
const StyledMain = styled("main")(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));
const StyledForm = styled("form")(() => ({
  marginBottom: 20,
  marginTop: 15,
}));
const AdditionalText = styled("section")(() => ({
  marginBottom: 20,
}));
const FormFieldContainer = styled(FormControl)(() => ({
  marginLeft: 0,
  marginBottom: 5,
  paddingLeft: 0,
  paddingBottom: 15,
  width: "100%",
}));
const FormQuestionContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    display: "flex",
  },
}));
const FormQuestionLabel = styled(FormLabel)(() => ({
  flexGrow: 1,
  color: "inherit",
}));
const QuestionError = styled("div")(() => ({
  height: 20,
  "& > p": {
    margin: 0,
  },
}));
const ButtonContainer = styled("div")(() => ({
  width: "100%",
  marginTop: 5,
}));
const StyledButton = styled(Button)(() => ({
  margin: "auto",
  display: "block",
}));
const SubmittingContainer = styled("div")(() => ({
  marginTop: 20,
  width: "100%",
}));
const SubmittingSpinner = styled(CircularProgress)(() => ({
  margin: "auto",
  display: "block",
}));
export const Survey: FunctionComponent = () => {
  const { tenant, setSnackbarError } = useAppContext();
  // const classes = useStyles();
  const brand = useBrand();
  const navigate = useNavigate();

  const [surveyState, setSurveyState] = useState(
    SurveyStateMachineState.FillingOut
  );

  const [usability, setUsability] = useState<number | null>(null);
  const [understandability, setUnderstandability] = useState<number | null>(
    null
  );
  const [helpfulness, setHelpfulness] = useState<number | null>(null);
  const [improvedExperience, setImprovedExperience] = useState<number | null>(
    null
  );
  const [recommend, setRecommend] = useState<SurveyRecommendation | null>(null);
  const [comments, setComments] = useState("");
  const [errors, setErrors] = useState<{ [inputName: string]: string }>({});

  const validateForm = () => {
    const requiredInputs = {
      usability,
      understandability,
      helpfulness,
      improvedExperience,
      recommend,
    };

    const formErrors: { [inputName: string]: string } = {};

    for (const inputName in requiredInputs) {
      if (
        requiredInputs[inputName as keyof typeof requiredInputs] === undefined
      ) {
        formErrors[inputName] = "Please enter a value for this field.";
      }
      // Any other validation (e.g. value range, enums) can go here.
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const submitSurvey = async () => {
    if (!validateForm()) {
      return;
    }

    setSurveyState(SurveyStateMachineState.Submitting);

    console.log("Survey:", {
      usability,
      understandability,
      helpfulness,
      improvedExperience,
      recommend,
      comments,
    });

    const surveysApi = getSurveysAPI();
    try {
      await surveysApi.addSurvey({
        conversationID: Session.getInstance().conversation?.id || "",
        aHTenantName: tenant,
        addSurvey: {
          usability: usability !== null ? usability : undefined,
          understandability:
            understandability !== null ? understandability : undefined,
          helpfulness: helpfulness !== null ? helpfulness : undefined,
          improvedExperience:
            improvedExperience !== null ? improvedExperience : undefined,
          recommend: recommend !== null ? recommend : undefined,
          comments,
        },
      });
      setSurveyState(SurveyStateMachineState.SuccessfullySubmitted);
    } catch (e) {
      if (e instanceof Response) {
        console.error("Encountered error from API when submitting survey:", e);
        setSnackbarError(`Unable to send message: ${e.statusText}`);
        setSurveyState(SurveyStateMachineState.FillingOut);
      } else {
        throw e;
      }
    }
  };

  return (
    <StyledMain>
      <ScrollToTopOnMount />

      <Typography variant="h3" component="div" gutterBottom>
        {brand.appName} Survey
      </Typography>

      {surveyState === SurveyStateMachineState.FillingOut && (
        <>
          <AdditionalText>
            Completing this survey helps train {brand.appName} to answer your
            questions more effectively!
          </AdditionalText>
          <StyledForm
            onSubmit={event => {
              submitSurvey();

              event.preventDefault();
              return false;
            }}
          >
            <FormQuestion
              inputName="usability"
              value={usability}
              setValue={setUsability}
              questionText="How easy did you find the assistant to use?"
              error={errors.usability}
              clearError={() => delete errors.usability}
            />

            <FormQuestion
              inputName="understandability"
              value={understandability}
              setValue={setUnderstandability}
              questionText="Are the answers easy to understand?"
              error={errors.understandability}
              clearError={() => delete errors.understandability}
            />

            <FormQuestion
              inputName="helpfulness"
              value={helpfulness}
              setValue={setHelpfulness}
              questionText="Was the information helpful to you?"
              error={errors.helpfulness}
              clearError={() => delete errors.helpfulness}
            />

            <FormQuestion
              inputName="improvedExperience"
              value={improvedExperience}
              setValue={setImprovedExperience}
              questionText="Did the assistant help to improve your overall experience of visiting the hospital?"
              error={errors.improvedExperience}
              clearError={() => delete errors.improvedExperience}
            />

            <FormFieldContainer error={errors.recommend !== undefined}>
              <FormQuestionContainer>
                <FormQuestionLabel>
                  <Typography variant="h6" component="div">
                    Would you recommend {brand.appName} to a friend?
                    <Typography
                      component="span"
                      sx={{ color: theme => theme.palette.error.main }}
                    >
                      *
                    </Typography>
                  </Typography>
                </FormQuestionLabel>
                <RadioGroup
                  row
                  aria-label="recommend"
                  name="recommend"
                  aria-required="true"
                  aria-describedby="recommend-helper-text"
                  value={recommend}
                  onChange={event => {
                    setRecommend(event.target.value as SurveyRecommendation);
                    delete errors.recommend;
                  }}
                >
                  <FormControlLabel
                    value={SurveyRecommendation.Recommended}
                    control={<Radio color="primary" />}
                    label="Yes"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value={SurveyRecommendation.NotSure}
                    control={<Radio color="primary" />}
                    label="Not Sure"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value={SurveyRecommendation.NotRecommended}
                    control={<Radio color="primary" />}
                    label="No"
                    labelPlacement="end"
                  />
                </RadioGroup>
              </FormQuestionContainer>

              <QuestionError id="recommend-helper-text">
                {errors.recommend && (
                  <FormHelperText error={true}>
                    {errors.recommend}
                  </FormHelperText>
                )}
              </QuestionError>
            </FormFieldContainer>

            <FormControl component="fieldset">
              <AdditionalText>
                We welcome any other comments you might have about using{" "}
                {brand.appName} to help us make it even better!
              </AdditionalText>
              <TextField
                value={comments}
                onChange={event => setComments(event.target.value)}
                id="comments"
                label="Comments"
                multiline
                variant="filled"
              />
            </FormControl>

            <ButtonContainer>
              <StyledButton
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={surveyState !== SurveyStateMachineState.FillingOut}
              >
                Submit
              </StyledButton>
            </ButtonContainer>
          </StyledForm>
        </>
      )}

      {surveyState === SurveyStateMachineState.Submitting && (
        <SubmittingContainer>
          <SubmittingSpinner size={100} />
        </SubmittingContainer>
      )}

      {surveyState === SurveyStateMachineState.SuccessfullySubmitted && (
        <div>
          <Typography variant="h5" component="div" gutterBottom>
            Thanks for the feedback!
          </Typography>

          <Typography>
            All of your input helps make {brand.botName} better.
          </Typography>

          <ButtonContainer>
            <StyledButton
              variant="contained"
              color="primary"
              size="large"
              onClick={() => navigate(-1)}
            >
              Back to chat
            </StyledButton>
          </ButtonContainer>
        </div>
      )}
    </StyledMain>
  );
};
