import { Card, CardHeader, CardMedia, styled } from "@mui/material";
import React, { FunctionComponent, useEffect, useState } from "react";

const MessageImage = styled(CardMedia)(() => ({
  height: 0,
  paddingTop: "56.25%", // Default to 16:9 aspect ratio
}));
const MessageImageContainer = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

interface ChatbotMessageImageProps {
  title?: string;
  description?: string;
  source: string;
}

export const ChatbotMessageImage: FunctionComponent<
  ChatbotMessageImageProps
> = ({ title, description, source }) => {
  const [imageAspectRatio, setImageAspectRatio] = useState(9 / 16);

  const dynamicImageStyles = { paddingTop: `${imageAspectRatio * 100}%` };

  useEffect(() => {
    const imgElement = new Image();
    imgElement.onload = () => {
      const aspectRatio = imgElement.naturalHeight / imgElement.naturalWidth;
      setImageAspectRatio(aspectRatio);
    };
    imgElement.src = source;
  }, [source]);

  return (
    <MessageImageContainer>
      <Card>
        {(title || description) && (
          <CardHeader title={title} subheader={description} />
        )}
        <MessageImage style={dynamicImageStyles} image={source} title={title} />
      </Card>
    </MessageImageContainer>
  );
};
