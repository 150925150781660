/* tslint:disable */
/* eslint-disable */
/**
 * Alder Hey Chat API
 * This RESTful API provides an interface to the Alder Hey chatbot.
 *
 * The version of the OpenAPI document: abbb7b6
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  AssistantRuntimeResponseGeneric,
  AssistantRuntimeResponseGenericFromJSON,
  AssistantRuntimeResponseGenericFromJSONTyped,
  AssistantRuntimeResponseGenericToJSON,
  Assistantv2DialogNodeAction,
  Assistantv2DialogNodeActionFromJSON,
  Assistantv2DialogNodeActionFromJSONTyped,
  Assistantv2DialogNodeActionToJSON,
  Assistantv2MessageOutputDebug,
  Assistantv2MessageOutputDebugFromJSON,
  Assistantv2MessageOutputDebugFromJSONTyped,
  Assistantv2MessageOutputDebugToJSON,
  Assistantv2RuntimeEntity,
  Assistantv2RuntimeEntityFromJSON,
  Assistantv2RuntimeEntityFromJSONTyped,
  Assistantv2RuntimeEntityToJSON,
  Assistantv2RuntimeIntent,
  Assistantv2RuntimeIntentFromJSON,
  Assistantv2RuntimeIntentFromJSONTyped,
  Assistantv2RuntimeIntentToJSON,
} from "./";

/**
 *
 * @export
 * @interface AssistantMessageOutput
 */
export interface AssistantMessageOutput {
  /**
   * An array of objects describing any actions requested by the dialog node.
   * @type {Array<Assistantv2DialogNodeAction>}
   * @memberof AssistantMessageOutput
   */
  actions?: Array<Assistantv2DialogNodeAction>;
  /**
   *
   * @type {Assistantv2MessageOutputDebug}
   * @memberof AssistantMessageOutput
   */
  debug?: Assistantv2MessageOutputDebug;
  /**
   * An array of entities identified in the user input.
   * @type {Array<Assistantv2RuntimeEntity>}
   * @memberof AssistantMessageOutput
   */
  entities?: Array<Assistantv2RuntimeEntity>;
  /**
   *
   * @type {Array<AssistantRuntimeResponseGeneric>}
   * @memberof AssistantMessageOutput
   */
  generic?: Array<AssistantRuntimeResponseGeneric>;
  /**
   * An array of intents recognized in the user input, sorted in descending order of confidence.
   * @type {Array<Assistantv2RuntimeIntent>}
   * @memberof AssistantMessageOutput
   */
  intents?: Array<Assistantv2RuntimeIntent>;
  /**
   * An object containing any custom properties included in the response. This object includes any arbitrary properties defined in the dialog JSON editor as part of the dialog node output.
   * @type {object}
   * @memberof AssistantMessageOutput
   */
  userDefined?: object;
}

export function AssistantMessageOutputFromJSON(
  json: any
): AssistantMessageOutput {
  return AssistantMessageOutputFromJSONTyped(json, false);
}

export function AssistantMessageOutputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AssistantMessageOutput {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    actions: !exists(json, "actions")
      ? undefined
      : (json["actions"] as Array<any>).map(
          Assistantv2DialogNodeActionFromJSON
        ),
    debug: !exists(json, "debug")
      ? undefined
      : Assistantv2MessageOutputDebugFromJSON(json["debug"]),
    entities: !exists(json, "entities")
      ? undefined
      : (json["entities"] as Array<any>).map(Assistantv2RuntimeEntityFromJSON),
    generic: !exists(json, "generic")
      ? undefined
      : (json["generic"] as Array<any>).map(
          AssistantRuntimeResponseGenericFromJSON
        ),
    intents: !exists(json, "intents")
      ? undefined
      : (json["intents"] as Array<any>).map(Assistantv2RuntimeIntentFromJSON),
    userDefined: !exists(json, "user_defined")
      ? undefined
      : json["user_defined"],
  };
}

export function AssistantMessageOutputToJSON(
  value?: AssistantMessageOutput | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    actions:
      value.actions === undefined
        ? undefined
        : (value.actions as Array<any>).map(Assistantv2DialogNodeActionToJSON),
    debug: Assistantv2MessageOutputDebugToJSON(value.debug),
    entities:
      value.entities === undefined
        ? undefined
        : (value.entities as Array<any>).map(Assistantv2RuntimeEntityToJSON),
    generic:
      value.generic === undefined
        ? undefined
        : (value.generic as Array<any>).map(
            AssistantRuntimeResponseGenericToJSON
          ),
    intents:
      value.intents === undefined
        ? undefined
        : (value.intents as Array<any>).map(Assistantv2RuntimeIntentToJSON),
    user_defined: value.userDefined,
  };
}
