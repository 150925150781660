import { ThemeProvider, createTheme, useMediaQuery } from "@mui/material";
import React, {
  Dispatch,
  ReactChild,
  ReactFragment,
  ReactPortal,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";

import { BrandTheme, useBrand } from "./brands/Brand";
import setTheme from "./styles/AppTheme";

export interface AppContextProps {
  clientName?: string;
  tenant?: string;
  isWidget: boolean;
  snackBarError: string;
  setSnackbarError: Dispatch<SetStateAction<string>>;
  isDialogShowing: boolean;
  setIsDialogShowing: Dispatch<SetStateAction<boolean>>;
  isFullscreen: boolean;
  setIsFullscreen: Dispatch<SetStateAction<boolean>>;
  pendingQuestion?: string;
  setPendingQuestion: Dispatch<SetStateAction<string | undefined>>;
  prefersDarkMode: boolean;
  colorType: "light" | "dark";
  toggleColorType: () => void;
  brandTheme?: BrandTheme;
  toggleFullScreen: () => void;
}

const AppContext = createContext<AppContextProps>({
  clientName: "",
  tenant: "",
  isWidget: false,
  snackBarError: "",
  setSnackbarError: () => {},
  isDialogShowing: false,
  setIsDialogShowing: () => {},
  isFullscreen: false,
  setIsFullscreen: () => {},
  pendingQuestion: undefined,
  setPendingQuestion: () => {},
  prefersDarkMode: false,
  colorType: "light",
  toggleColorType: () => {},
  brandTheme: undefined,
  toggleFullScreen: () => {},
});

export const AppContextProvider = (props: {
  children:
    | boolean
    | ReactChild
    | ReactFragment
    | ReactPortal
    | null
    | undefined;
}) => {
  const clientName = process.env.REACT_APP_CLIENT_NAME || undefined;
  const tenant = process.env.REACT_APP_TENANT || undefined;
  const rootContainer = document.getElementById("ah-chat-container");
  const isWidgetAttr =
    rootContainer && rootContainer.getAttribute("data-is-widget");
  const isWidget = isWidgetAttr !== null && isWidgetAttr !== "false";

  // The 'fullscreen' only applies to the widget version.
  const [isFullscreen, setIsFullscreen] = useState(!isWidget);
  const [isDialogShowing, setIsDialogShowing] = useState(false);
  const [snackBarError, setSnackbarError] = useState("");
  const [pendingQuestion, setPendingQuestion] = useState<string | undefined>(
    undefined
  );
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [colorType, setColorType] = useState<"light" | "dark">(
    prefersDarkMode ? "dark" : "light"
  );
  const brand = useBrand();
  const toggleColorType = useMemo(() => {
    return () => {
      setColorType(prevColorType =>
        prevColorType === "light" ? "dark" : "light"
      );
    };
  }, [setColorType]);
  const themeUpdated = useMemo(
    () => createTheme(setTheme(colorType, brand.theme)),
    [colorType, brand]
  );
  const toggleFullScreen = () => {
    if (isWidget) {
      setIsFullscreen(prevIsFullscreen => !prevIsFullscreen);
    }
  };
  // const setPendingQuestion = useCallback(
  //   (value?: string) => setPendingQuestionState(value),
  //   []
  // );

  return (
    <AppContext.Provider
      value={{
        clientName,
        tenant,
        isWidget,
        snackBarError,
        setSnackbarError,
        isDialogShowing,
        setIsDialogShowing,
        isFullscreen,
        setIsFullscreen,
        pendingQuestion,
        setPendingQuestion,
        prefersDarkMode,
        colorType,
        toggleColorType,
        toggleFullScreen,
      }}
    >
      <ThemeProvider theme={themeUpdated}>{props.children}</ThemeProvider>
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const appContext = useContext(AppContext);
  if (!appContext) {
    throw new Error("useAppContext must be used within an AppContextProvider");
  }
  return appContext;
};
