import SendIcon from "@mui/icons-material/Send";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
  styled,
} from "@mui/material";
import React, { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";

import { useAppContext } from "../AppContext";
import { useBrand } from "../brands/Brand";
import { ScrollToTopOnMount } from "../components/ScrollToTopOnMount";
import { constants } from "../helpers/Constants";

interface StyledContainerProps {
  colorMode: "light" | "dark";
}
const StyledContainer = styled(Paper)<StyledContainerProps>(
  ({ theme, colorMode }) => ({
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    " & a": {
      color:
        colorMode === "dark"
          ? theme.palette.secondary.main
          : theme.palette.primary.main,
    },
  })
);
const StyledSection = styled("section")(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));
const SectionContent = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  "& p:not(:first-of-type)": {
    paddingTop: theme.spacing(1),
  },
}));
const TermsList = styled(List)(({ theme }) => ({
  listStyleType: "unset",
  marginLeft: theme.spacing(2),
}));
const TermItem = styled(ListItem)(() => ({
  display: "list-item",
  paddingBottom: 0,
}));

export const About: FunctionComponent = () => {
  const navigate = useNavigate();
  // const classes = useStyles();
  const brand = useBrand();
  const { setPendingQuestion, colorType } = useAppContext();

  const closeAndAskQuestion = (question: string) => {
    setPendingQuestion(question);
    navigate(constants.paths.chat);
  };

  return (
    <StyledContainer colorMode={colorType}>
      <ScrollToTopOnMount />

      <Typography variant="h3" component="div" gutterBottom>
        Using {brand.appName}
      </Typography>

      <StyledSection>
        <Typography variant="h4" component="div">
          Terms of use
        </Typography>

        <TermsList>
          {brand.about.termsOfUseStatementsExpanded.map((termText, index) => (
            <TermItem key={index}>
              <ListItemText primary={termText} />
            </TermItem>
          ))}
        </TermsList>
      </StyledSection>

      <StyledSection>
        <Typography variant="h4" component="div">
          Example questions
        </Typography>

        <SectionContent>
          <Typography>
            Stuck on what to ask? Here&apos;s some to get you started. Click the
            question to ask {brand.botName}:
          </Typography>

          <List>
            {brand.about.questions.map((questionText, index) => (
              <ListItem
                button
                key={index}
                onClick={() => closeAndAskQuestion(questionText)}
              >
                <ListItemIcon>
                  <SendIcon />
                </ListItemIcon>
                <ListItemText primary={questionText} />
              </ListItem>
            ))}
          </List>
        </SectionContent>
      </StyledSection>

      <StyledSection>
        <Typography variant="h4" component="div">
          What if I want to talk to a real person?
        </Typography>

        <SectionContent>
          <Typography>{brand.about.escalation}</Typography>
        </SectionContent>
      </StyledSection>

      <StyledSection>
        <Typography variant="h4" component="div">
          Personal Information
        </Typography>

        <SectionContent>
          <Typography>
            Please <b>do not enter any personally identifying information</b>
            such as your name and other people&apos;s names and (email)
            addresses when asking questions.
          </Typography>
        </SectionContent>
      </StyledSection>

      <StyledSection>
        <Typography variant="h4" component="div">
          Privacy statement
        </Typography>

        <SectionContent>
          <Typography>
            {brand.provider} take no responsibility for the content of external
            internet links accessed via the system. {brand.provider} may suspend
            the chatbot service without notice at any time for maintenance
            purposes.
          </Typography>
        </SectionContent>
      </StyledSection>
    </StyledContainer>
  );
};
