import React, { FunctionComponent } from "react";

interface IframeMessageProps {
  src: string;
  title?: string;
}

export const IframeMessage: FunctionComponent<IframeMessageProps> = ({
  src,
  title,
}) => {
  return (
    <div
      style={{
        width: "100%",
        height: "0",
        paddingBottom: "56.25%",
        position: "relative",
      }}
    >
      <iframe
        src={src}
        title={title || "Embedded content"}
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          border: "0",
        }}
        allowFullScreen
      />
    </div>
  );
};
