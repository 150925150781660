import { Box, styled } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import React, { FunctionComponent } from "react";

const RatingContainer = styled(Box)(({ theme }) => ({
  float: "right",
  marginLeft: theme.spacing(1),
  position: "absolute",
  bottom: -10,
  right: 10,
  backgroundColor: theme.palette.primary.main,
  borderRadius: theme.shape.borderRadius + 8,
}));
const RatingButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButton-root": {
    borderRadius: theme.shape.borderRadius + 8,
    border: undefined,
    padding: theme.spacing(0.1),
    color: theme.palette.primary.contrastText,
  },
  "& .MuiToggleButtonGroup-grouped:not(:last-child)": {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    width: theme.spacing(7),
  },
  "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    width: theme.spacing(7),
  },
}));

interface MessageRatingProps {
  value?: number;
  onChanged: (rating?: number) => void;
}

export const MessageRating: FunctionComponent<MessageRatingProps> = ({
  value,
  onChanged,
}) => {
  return (
    <RatingContainer>
      <RatingButtonGroup
        exclusive
        value={value}
        onChange={(_event, newValue) => onChanged(newValue)}
      >
        <ToggleButton value={5}>
          <span role="img" aria-label="Like this answer">
            👍
          </span>
        </ToggleButton>
        <ToggleButton value={1}>
          <span role="img" aria-label="Dislike this answer">
            👎
          </span>
        </ToggleButton>
      </RatingButtonGroup>
    </RatingContainer>
  );
};
