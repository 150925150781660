import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Link,
  Typography,
  styled,
} from "@mui/material";
import React, { FunctionComponent } from "react";

import { Assistantv2SearchResult } from "../api_client";
import { useAppContext } from "../AppContext";
import { useBrand } from "../brands/Brand";
import { MessageSide } from "../typings/chat_types";

interface MessageRowProps {
  side: MessageSide;
}
const MessageRow = styled(Grid)<MessageRowProps>(({ side }) => ({
  textAlign: side === MessageSide.Left ? "left" : "right",
}));
const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const sanitiseSearchText = (text: string) => {
  // Bullet points get converted to `$_{1.}$`.
  return (
    text
      // First bullet point should go to nothing.
      .replace(/\$_{1\.}\$/g, "")
      // Second bullet point should have a full stop to maintain grammatical sense.
      .replace(/\s*\$_{\d+\.}\$/g, ". ")
      // Ligatures turn into weird GLYPH characters.
      .replace(/GLYPH<f_f>/g, "ff")
      // Remove leading full stop and white space.
      .replace(/^\.?\s*/, "")
  );
};

const truncate = (text: string, toLength: number) =>
  text.substring(0, toLength) + "...";

const truncateTexts = (paragraphs: string[], toLength: number) => {
  const truncated: string[] = [];
  let characterCount = 0;

  for (const text of paragraphs) {
    if (characterCount + text.length > toLength) {
      truncated.push(truncate(text, toLength - characterCount));
      break;
    }

    truncated.push(text);
    characterCount += text.length;
  }

  return truncated;
};

interface ChatbotMessageSearchCardProps {
  result: Assistantv2SearchResult;
  side: MessageSide;
}

export const ChatbotMessageSearchCard: FunctionComponent<
  ChatbotMessageSearchCardProps
> = ({ result, side }) => {
  const { isFullscreen } = useAppContext();
  const brand = useBrand();

  const maxBodyLength = 300;
  const rawBody =
    result.body !== undefined ? (JSON.parse(result.body) as string[]) : [];
  const body = truncateTexts(rawBody.map(sanitiseSearchText), maxBodyLength);

  // If we start mid-way through a sentence, prepend an ellipsis.
  const initialChar = body[0] && body[0][0];
  if (initialChar && initialChar.toLowerCase() === initialChar) {
    body[0] = "..." + body[0];
  }

  const maxTitleLength = 100;
  const rawTitles =
    result.title !== undefined ? (JSON.parse(result.title) as string[]) : [];
  const titles = truncateTexts(
    rawTitles.map(sanitiseSearchText),
    maxTitleLength
  );

  return (
    <MessageRow item xs={12} sm={isFullscreen ? 6 : undefined} side={side}>
      <StyledCard>
        <CardContent>
          <Typography color="textSecondary" gutterBottom>
            Search Result
          </Typography>
          {titles.map((titleText, index) => (
            <Typography key={index} variant="h6" component="div">
              {titleText}
            </Typography>
          ))}
          {body.map((paragraphText, index) => (
            <Typography key={index} variant="body2" component="p">
              {paragraphText}
            </Typography>
          ))}
        </CardContent>

        {Boolean(result.url) && (
          <CardActions>
            <Button
              variant="contained"
              disableElevation={true}
              color="primary"
              size="small"
            >
              <Link
                underline="none"
                color="inherit"
                href={result.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {brand.search?.openLinkText || "Open Document"}
              </Link>
            </Button>
          </CardActions>
        )}
      </StyledCard>
    </MessageRow>
  );
};
