import { Paper, styled } from "@mui/material";
import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  ViewmodelAddMessageInteractionEnum as InteractionType,
  getMessagesAPI,
} from "../api_client";
import { useAppContext } from "../AppContext";
import { ChatInput } from "../components/ChatInput";
import { ChatMessage } from "../components/ChatMessage";
import { MessageGroup } from "../components/MessageGroup";
import { SessionExpiredDialog } from "../components/SessionExpiredDialog";
import { TypingIndicator } from "../components/TypingIndicator";
import { constants } from "../helpers/Constants";
import { Session } from "../helpers/Session";
import {
  countAuthorAndTurnId,
  getRandomRange,
  redactPII,
} from "../helpers/Utils";
import {
  ChatbotMessageInfo,
  MessageAuthor,
  MessageInfo,
  MessageSide,
  UserMessageInfo,
  UserMessageInput,
} from "../typings/chat_types";
import { Loading } from "./Loading";

// Message to match against "greeting" intent regardless of which chatbot you're using.
// Assumes underlying chatbot responds with welcome message when greeted with below
// message. This could be further customised if needed (e.g. other languages).
const greetingMessage = "Hi";

// If answers come back with precise timing, it looks off. Add a bit of randomness
// to make it feel more conversational.
const getRandomDelay = () => getRandomRange(500, 1000);

interface ChatContainerProps {
  isFullscreen: boolean;
}

const ChatOuterContainer = styled(Paper)(({ theme }) => ({
  height: "100%",
  backgroundColor: theme.palette.background.default,
  display: "grid",
  gridTemplateColumns: "repeat(12, 1fr)",
  gridTemplateRows: "1fr 56px",
  gridTemplateAreas: "'c c c c c c c c c c c c' 'f f f f f f f f f f f f'",
}));
const ChatContainer = styled("div")<ChatContainerProps>(
  ({ theme, isFullscreen }) => ({
    gridArea: "c",
    paddingTop: theme.spacing(2),
    paddingBottom: isFullscreen ? theme.spacing(10) : theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(3),
  })
);
const MessageOuterContainer = styled("div")(() => ({
  position: "relative",
}));

enum ChatCommands {
  Clear = "clear",
}

// When functions are needed inside and outside effects, the recommended way to deal
// with this is to hoist the function. To prevent having to repeatedly include loads of
// arguments, here we curry the function for ease of use.
const curryAskChatbotQuestion = (
  setMessages: React.Dispatch<React.SetStateAction<MessageInfo[]>>,
  disableExistingOptions: () => void,
  setSessionExpiredDialogOpen: (value: boolean) => void,
  setShowTypingIndicator: (value: boolean) => void,
  tenant: string | undefined,
  setMessageQueue: React.Dispatch<React.SetStateAction<ChatbotMessageInfo[]>>,
  setLoading: (value: boolean) => void,
  setSnackbarError: (message: string) => void
) => {
  const session = Session.getInstance();

  return async (
    messageInput: UserMessageInput | string,
    interaction: InteractionType
  ) => {
    const request =
      typeof messageInput === "string" ? { text: messageInput } : messageInput;

    disableExistingOptions();

    const userMessageInfo: UserMessageInfo = {
      author: MessageAuthor.User,
      request,
      interaction,
      userID: session.userId,
    };

    if (messageInput === ChatCommands.Clear) {
      session.messages = [];
      setMessages([]);
    } else {
      setMessages(currentMessages => [...currentMessages, userMessageInfo]);
    }

    if (!session.hasValidConversation()) {
      setSessionExpiredDialogOpen(true);
      return;
    }

    const conversation = session.conversation!;
    setShowTypingIndicator(true);

    try {
      const messagesApi = getMessagesAPI();
      const response = await messagesApi.addMessage({
        conversationID: conversation.id!,
        addMessage: userMessageInfo,
        aHTenantName: tenant,
      });

      // Depending on how long the abov async API call takes, the actual session
      // conversation can be invalid or undefined by now.
      session.extendConversationExpiry();

      const chatbotMessages: ChatbotMessageInfo[] =
        response.assistantResponse?.output.generic?.map(responseMessage => ({
          author: MessageAuthor.Chatbot,
          disabled: false,
          turnId: response.id,
          ...responseMessage,
        })) ?? [];

      // TODO: continue working on this in the future for the Message Rating feature
      // const chatbotMessagesCount = chatbotMessages.filter(message =>
      //   message.responseType !== "pause" &&
      //   message.responseType !== "options" &&
      //   message.responseType !== "multioptions")
      // .map(message => ({
      //   ...message,
      //   count: chatbotMessages.length,
      // }));
      if (chatbotMessages.length === 0) {
        setShowTypingIndicator(false);
      }

      setMessageQueue(currentMessages => [
        ...currentMessages,
        ...chatbotMessages,
      ]);
    } catch (e) {
      setShowTypingIndicator(false);
      if (e instanceof Response) {
        if (e.status === 400) {
          // Bad request indicates that the conversation has expired and is invalid.
          setSessionExpiredDialogOpen(true);
          return;
        }
        console.error("Encountered error from API when sending message:", e);
        setSnackbarError(`Unable to send message: ${e.statusText}`);
      } else {
        throw e;
      }
    }

    setLoading(false);
  };
};

export const Chat: FunctionComponent = () => {
  const {
    clientName,
    tenant,
    setSnackbarError,
    pendingQuestion,
    setPendingQuestion,
    isFullscreen,
    setIsDialogShowing,
  } = useAppContext();
  const navigate = useNavigate();

  const sessionMessages = Session.getInstance().messages;
  const sessionMessageQueue = Session.getInstance().messageQueue;
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState(sessionMessages);
  const [messageQueue, setMessageQueue] =
    useState<ChatbotMessageInfo[]>(sessionMessageQueue);
  const [processingQueue, setProcessingQueue] = useState(false);
  const [shouldShowNextMessage, setShouldShowNextMessage] = useState(false);
  const [showTypingIndicator, setShowTypingIndicator] = useState(false);
  const [sessionExpiredDialogOpen, setSessionExpiredDialogOpen] =
    useState(false);
  // const [countMessages, setCountMessages] = useState(0);
  const messagesEndRef = useRef<HTMLInputElement>(null);

  // When component unmounts (i.e. we move pages), stop processing queue. We'll start
  // processing it again once we come back into the element.
  useEffect(() => () => setProcessingQueue(false), []);

  useEffect(() => {
    // Make sure that if the message queue has changed, we're processing it.
    if (processingQueue || messageQueue.length === 0) {
      return;
    }

    setProcessingQueue(true);
    setShouldShowNextMessage(true);
  }, [messageQueue, processingQueue]);

  useEffect(() => {
    if (!shouldShowNextMessage) {
      return;
    }

    setShouldShowNextMessage(false);

    const nextMessage = messageQueue[0];

    if (nextMessage === undefined) {
      // We've finished processing our queue already.
      setShowTypingIndicator(false);
      setProcessingQueue(false);
      return;
    }

    setMessageQueue(currentMessages => currentMessages.slice(1));

    if (nextMessage.responseType === "pause") {
      setShowTypingIndicator(nextMessage.typing!);

      window.setTimeout(
        () => setShouldShowNextMessage(true),
        nextMessage.time!
      );
      return;
    }

    setMessages(currentMessages => [...currentMessages, nextMessage]);

    // This was the final message in the queue, so we're finished now.
    if (messageQueue.length === 1) {
      setShowTypingIndicator(false);
      setProcessingQueue(false);
      return;
    }

    setShowTypingIndicator(true);

    window.setTimeout(() => {
      setShouldShowNextMessage(true);
    }, getRandomDelay());
  }, [shouldShowNextMessage, messageQueue]);

  useEffect(() => {
    if (
      pendingQuestion === undefined ||
      !Session.getInstance().hasValidConversation() ||
      sessionExpiredDialogOpen
    ) {
      return;
    }

    setPendingQuestion(undefined);

    const askChatbotQuestionInEffect = curryAskChatbotQuestion(
      setMessages,
      disableExistingOptions,
      setSessionExpiredDialogOpen,
      setShowTypingIndicator,
      tenant,
      setMessageQueue,
      setLoading,
      setSnackbarError
    );

    const askPendingQuestion = async () =>
      await askChatbotQuestionInEffect(
        pendingQuestion,
        InteractionType.TextInput
      );

    askPendingQuestion();
  }, [
    pendingQuestion,
    setPendingQuestion,
    setSnackbarError,
    tenant,
    sessionExpiredDialogOpen,
  ]);

  const initialisingConversation = useRef(false);

  useEffect(() => {
    const session = Session.getInstance();
    // If our conversation is valid but we have no messages, this indicates that the
    // initial conversation starter XHR finished after unmounting the component. In
    // this case, just re-start the conversation.
    if (session.hasValidConversation() || sessionExpiredDialogOpen) {
      setLoading(false);
      return;
    }

    if (initialisingConversation.current) {
      return;
    }

    initialisingConversation.current = true;

    const askChatbotQuestionInEffect = curryAskChatbotQuestion(
      setMessages,
      disableExistingOptions,
      setSessionExpiredDialogOpen,
      setShowTypingIndicator,
      tenant,
      setMessageQueue,
      setLoading,
      setSnackbarError
    );

    const askInitialConversationStarter = async () =>
      await askChatbotQuestionInEffect(
        greetingMessage,
        InteractionType.Autoprompt
      );

    const askPendingQuestion = async () => {
      if (pendingQuestion === undefined) {
        return;
      }

      setPendingQuestion(undefined);

      await askChatbotQuestionInEffect(
        pendingQuestion,
        InteractionType.TextInput
      );
    };

    // Get our conversation ready for when we need it.
    // Todo: Only do this if we have a brand new or expired conversation, no messages.
    const initConversation = async () => {
      setMessages([]);
      try {
        await session.startNewConversation(clientName!, tenant);
        await askInitialConversationStarter();
        await askPendingQuestion();
      } catch (e) {
        if (e instanceof Response) {
          console.error(
            "Encountered error from API when starting conversation:",
            e
          );
          setSnackbarError(`Unable to start new conversation: ${e.statusText}`);
        } else {
          throw e;
        }
      } finally {
        initialisingConversation.current = false;
      }
    };

    initConversation();
  }, [
    clientName,
    sessionExpiredDialogOpen,
    tenant,
    pendingQuestion,
    setPendingQuestion,
    setSnackbarError,
  ]);

  const handleRatingChanged = (message: MessageInfo, newRating?: number) => {
    if (message.author !== MessageAuthor.Chatbot) {
      return;
    }

    setMessages(currentMessages => {
      const updatedMessages = [...currentMessages];
      const updatedIdx = updatedMessages.indexOf(message);
      updatedMessages[updatedIdx] = { ...message, rating: newRating };
      return updatedMessages;
    });

    const session = Session.getInstance();

    const patchMessage = async () => {
      if (newRating === undefined) {
        return;
      }
      if (
        session.conversation?.id === undefined ||
        message.turnId === undefined
      ) {
        console.error(
          "Unable to update message rating because conversation and/or message is invalid."
        );
        return;
      }
      try {
        await getMessagesAPI().patchMessage({
          messageId: message.turnId,
          conversationID: session.conversation.id,
          aHTenantName: tenant,
          updateMessage: {
            score: newRating,
          },
        });
      } catch (e) {
        if (e instanceof Response) {
          console.error(
            "Encountered error from API when submitting Like/Dislike",
            e
          );
          setSnackbarError(`Unable to send message: ${e.statusText}`);
        } else {
          throw e;
        }
      }
    };
    patchMessage();
  };

  useEffect(() => {
    const scrollToBottom = () => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      }
    };
    scrollToBottom();
  });

  // Keep messages and message queue in sync w/ local storage.
  // Important: you can't use 'session' as a dependency because the serialisation of the
  // class breaks the local storage sync mechanism.
  useEffect(() => {
    Session.getInstance().messages = messages;
  }, [messages]);
  useEffect(() => {
    Session.getInstance().messageQueue = messageQueue;
  }, [messageQueue]);

  // Use an interval to check whether conversation has expired.
  useEffect(() => {
    // Every half a second or so should do.
    const checkPeriod = 1000;
    const session = Session.getInstance();

    const interval = window.setInterval(() => {
      const now = new Date();

      if (
        !loading &&
        session.conversation !== undefined &&
        session.expiryDate < now
      ) {
        setSessionExpiredDialogOpen(true);
      }
    }, checkPeriod);

    return () => window.clearInterval(interval);
  }, [loading]);

  useEffect(() => {
    setIsDialogShowing(sessionExpiredDialogOpen);
  }, [sessionExpiredDialogOpen, setIsDialogShowing]);

  const disableExistingOptions = () => {
    // Disable any already rendered option groups.
    setMessages(currentMessages =>
      currentMessages.map(thisMessage => {
        if (thisMessage.author === MessageAuthor.Chatbot) {
          return { ...thisMessage, disabled: true };
        }

        return thisMessage;
      })
    );
  };

  // Messages from the same source are grouped together inside a message group, so we
  // need to do a wee reduction of the messages so ensure consecutive messages from the
  // same author are combined together.
  const groupedMessages = messages.reduce((accumulator, value) => {
    const lastElement = accumulator[accumulator.length - 1];
    if (lastElement && lastElement[0].author === value.author) {
      // The message(s) in the previous list of messages has the same author as the
      // current message, so combine them into the same list.
      accumulator[accumulator.length - 1] = [...lastElement, value];
      return accumulator;
    }

    // Either the previous list of messages doesn't exist, or the author is different to
    // the current message.
    return [...accumulator, [value]];
  }, [] as MessageInfo[][]);

  const askChatbotQuestion = curryAskChatbotQuestion(
    setMessages,
    disableExistingOptions,
    setSessionExpiredDialogOpen,
    setShowTypingIndicator,
    tenant,
    setMessageQueue,
    setLoading,
    setSnackbarError
  );

  return (
    <ChatOuterContainer elevation={0}>
      <ChatContainer isFullscreen={isFullscreen}>
        {loading && <Loading />}

        {loading ||
          groupedMessages.map(
            (
              groupMessages: any[],
              groupIndex: React.Key | null | undefined
            ) => (
              <MessageGroup
                key={groupIndex}
                author={groupMessages[0].author}
                interactionType={groupMessages[0].interaction}
              >
                {groupMessages.map((message, messageIndex) => (
                  <MessageOuterContainer key={messageIndex}>
                    <ChatMessage
                      isFirstItem={messageIndex === 0}
                      isLastItem={
                        messageIndex + 1 ===
                        countAuthorAndTurnId(messages, 1, message.turnId)
                      }
                      message={message}
                      onOptionSelection={selection => {
                        askChatbotQuestion(
                          selection,
                          InteractionType.OptionSelection
                        );
                      }}
                      onRatingChanged={rating =>
                        handleRatingChanged(message, rating)
                      }
                    />
                  </MessageOuterContainer>
                ))}
              </MessageGroup>
            )
          )}

        {!loading && !sessionExpiredDialogOpen && showTypingIndicator && (
          <MessageGroup author={MessageAuthor.Chatbot} showAvatar={false}>
            <TypingIndicator side={MessageSide.Left} />
          </MessageGroup>
        )}

        <div ref={messagesEndRef} />
      </ChatContainer>

      <ChatInput
        gridArea="f"
        onAsk={question => {
          const redactedQuestion = redactPII(question);
          askChatbotQuestion(redactedQuestion, InteractionType.TextInput);
        }}
      />

      <SessionExpiredDialog
        open={sessionExpiredDialogOpen}
        onClose={() => {
          Session.getInstance().clearConversation();
          setSessionExpiredDialogOpen(false);
          navigate(constants.paths.welcome);
        }}
      />
    </ChatOuterContainer>
  );
};
