/* tslint:disable */
/* eslint-disable */
/**
 * Alder Hey Chat API
 * This RESTful API provides an interface to the Alder Hey chatbot.
 *
 * The version of the OpenAPI document: abbb7b6
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ViewmodelAddSurvey
 */
export interface ViewmodelAddSurvey {
  /**
   *
   * @type {string}
   * @memberof ViewmodelAddSurvey
   */
  comments?: string;
  /**
   *
   * @type {number}
   * @memberof ViewmodelAddSurvey
   */
  helpfulness?: number;
  /**
   *
   * @type {number}
   * @memberof ViewmodelAddSurvey
   */
  improvedExperience?: number;
  /**
   *
   * @type {string}
   * @memberof ViewmodelAddSurvey
   */
  recommend?: ViewmodelAddSurveyRecommendEnum;
  /**
   *
   * @type {number}
   * @memberof ViewmodelAddSurvey
   */
  understandability?: number;
  /**
   *
   * @type {number}
   * @memberof ViewmodelAddSurvey
   */
  usability?: number;
}

export function ViewmodelAddSurveyFromJSON(json: any): ViewmodelAddSurvey {
  return ViewmodelAddSurveyFromJSONTyped(json, false);
}

export function ViewmodelAddSurveyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ViewmodelAddSurvey {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    comments: !exists(json, "comments") ? undefined : json["comments"],
    helpfulness: !exists(json, "helpfulness") ? undefined : json["helpfulness"],
    improvedExperience: !exists(json, "improvedExperience")
      ? undefined
      : json["improvedExperience"],
    recommend: !exists(json, "recommend") ? undefined : json["recommend"],
    understandability: !exists(json, "understandability")
      ? undefined
      : json["understandability"],
    usability: !exists(json, "usability") ? undefined : json["usability"],
  };
}

export function ViewmodelAddSurveyToJSON(
  value?: ViewmodelAddSurvey | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    comments: value.comments,
    helpfulness: value.helpfulness,
    improvedExperience: value.improvedExperience,
    recommend: value.recommend,
    understandability: value.understandability,
    usability: value.usability,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum ViewmodelAddSurveyRecommendEnum {
  Recommended = "recommended",
  NotRecommended = "not_recommended",
  NotSure = "not_sure",
}
