/* tslint:disable */
/* eslint-disable */
/**
 * Alder Hey Chat API
 * This RESTful API provides an interface to the Alder Hey chatbot.
 *
 * The version of the OpenAPI document: abbb7b6
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Assistantv2DialogNodesVisited
 */
export interface Assistantv2DialogNodesVisited {
  /**
   * The conditions that trigger the dialog node.
   * @type {string}
   * @memberof Assistantv2DialogNodesVisited
   */
  conditions?: string;
  /**
   * A dialog node that was triggered during processing of the input message.
   * @type {string}
   * @memberof Assistantv2DialogNodesVisited
   */
  dialogNode?: string;
  /**
   * The title of the dialog node.
   * @type {string}
   * @memberof Assistantv2DialogNodesVisited
   */
  title?: string;
}

export function Assistantv2DialogNodesVisitedFromJSON(
  json: any
): Assistantv2DialogNodesVisited {
  return Assistantv2DialogNodesVisitedFromJSONTyped(json, false);
}

export function Assistantv2DialogNodesVisitedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Assistantv2DialogNodesVisited {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    conditions: !exists(json, "conditions") ? undefined : json["conditions"],
    dialogNode: !exists(json, "dialog_node") ? undefined : json["dialog_node"],
    title: !exists(json, "title") ? undefined : json["title"],
  };
}

export function Assistantv2DialogNodesVisitedToJSON(
  value?: Assistantv2DialogNodesVisited | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    conditions: value.conditions,
    dialog_node: value.dialogNode,
    title: value.title,
  };
}
