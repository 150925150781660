import { AppBar, Button, Toolbar, styled } from "@mui/material";
import TextField from "@mui/material/TextField";
import React, { FunctionComponent, useState } from "react";

import { useAppContext } from "../AppContext";

interface InputBarProps {
  gridArea?: string;
  isFullscreen: boolean;
}
const InputBar = styled(AppBar, {
  shouldForwardProp: prop => prop !== "isFullscreen" && prop !== "gridArea",
})<InputBarProps>(({ theme, gridArea, isFullscreen }) => ({
  top: "auto",
  bottom: 0,
  alignItems: "center",
  justifyContent: "center",
  transition: "border 0.5s ease-in-out",
  gridArea: gridArea || undefined,
  borderRadius: isFullscreen ? "" : "0 0 20px 20px",
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  width: "100%",
  maxWidth: theme.breakpoints.values.md,
}));
const StyledInputWrapper = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    height: theme.spacing(2),
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.secondary.main,
      borderWidth: theme.spacing(0.4),
    },
    "&:hover fieldset": {
      borderColor: theme.tertiaryColor.light,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.main,
    },
    "&.Mui-focused": {
      color: theme.palette.getContrastText(theme.palette.background.paper),
    },
  },
  "& .MuiFormLabel-root": {
    color: theme.palette.secondary.dark,
    "&.Mui-focused": {
      color: theme.palette.primary.light,
    },
    "&.Mui-hover": {
      color: theme.tertiaryColor.main,
    },
  },
}));

interface ChatInputProps {
  onAsk: (question: string) => void;
  gridArea?: string;
}

export const ChatInput: FunctionComponent<ChatInputProps> = props => {
  const { isFullscreen } = useAppContext();
  const [askInput, setAskInput] = useState("");

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAskInput(event.target.value);
  };

  const handleAskButtonClick = () => {
    if (askInput !== "") {
      props.onAsk(askInput);
      setAskInput("");
    }
  };

  const catchReturn = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      handleAskButtonClick();
    }
  };

  return (
    <InputBar
      elevation={3}
      position="fixed"
      isFullscreen={isFullscreen}
      gridArea={props.gridArea}
    >
      <StyledToolbar>
        <StyledInputWrapper
          variant="outlined"
          label="Chat"
          autoFocus
          InputLabelProps={{
            shrink: true,
          }}
          onKeyDown={catchReturn}
          onChange={handleTextFieldChange}
          value={askInput}
          fullWidth={true}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleAskButtonClick}
          sx={{
            height: theme => theme.spacing(7),
            marginLeft: theme => theme.spacing(2),
          }}
          size="large"
          disabled={askInput === ""}
        >
          Send
        </Button>
      </StyledToolbar>
    </InputBar>
  );
};
