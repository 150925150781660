import { WarningTwoTone as WarningIcon } from "@mui/icons-material";
import React from "react";

import { BrandTheme } from "./Brand";

// NHS identity guidelines: https://www.england.nhs.uk/nhsidentity/identity-guidelines/colours/
export const nhsTheme: BrandTheme = {
  primaryColour: "#005EB8", // NHS Blue
  secondaryColour: "#78BE20", // NHS Light Green
};

interface DefaultEscalationProps {
  appName: string;
  companyName: string;
}

const DefaultEscalation: React.FC<DefaultEscalationProps> = ({
  appName,
  companyName,
}) => (
  <span>
    {appName} is a computer programme which cannot answer questions specific to
    a particular user or their specific care. If you need further advice from a
    member of staff you could contact {companyName} directly.
  </span>
);

export const defaults = {
  questions: [
    "How much is the car park?",
    "Where are the toilets?",
    "What should I do when I get to the hospital?",
    "How long will my operation take?",
    "What is a blood test?",
  ],
  welcome: {
    titleIcon: {
      iconLight: WarningIcon,
      iconDark: WarningIcon,
      alt: "Warning Icon",
      round: true,
    },
    title: "This is a digital assistant. You are not speaking with a person.",
    info: {
      purposeStatement: {
        text: "For use by patients, families and visitors.",
      },
      termsOfUseStatements: [
        {
          summaryInBold: "Limitations",
          details: `IT DOES NOT GIVE DIAGNOSTIC OR PERSONAL MEDICAL ADVICE. FOR URGENT
          REQUESTS PLEASE CONTACT THE HOSPITAL DIRECTLY AND SPEAK TO A HEALTH
          PROFESSIONAL.`,
        },
        {
          summaryInBold: "Anonimity & Data Use",
          details:
            "Your questions are anonymous, but we do need to store them to train the system.",
        },
      ],
    },
    courtesy: {
      text: "Courtesy of Alder Hey Innovations and Hartree Centre",
      link: "https://www.hartree.stfc.ac.uk/Pages/Cognitive-hospital.aspx",
    },
  },
  about: {
    escalation: DefaultEscalation,
  },
  themes: {
    nhs: nhsTheme,
  },
};
