/* tslint:disable */
/* eslint-disable */
/**
 * Alder Hey Chat API
 * This RESTful API provides an interface to the Alder Hey chatbot.
 *
 * The version of the OpenAPI document: abbb7b6
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Assistantv2RuntimeEntityRole
 */
export interface Assistantv2RuntimeEntityRole {
  /**
   * The relationship of the entity to the range.
   * @type {string}
   * @memberof Assistantv2RuntimeEntityRole
   */
  type?: string;
}

export function Assistantv2RuntimeEntityRoleFromJSON(
  json: any
): Assistantv2RuntimeEntityRole {
  return Assistantv2RuntimeEntityRoleFromJSONTyped(json, false);
}

export function Assistantv2RuntimeEntityRoleFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Assistantv2RuntimeEntityRole {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: !exists(json, "type") ? undefined : json["type"],
  };
}

export function Assistantv2RuntimeEntityRoleToJSON(
  value?: Assistantv2RuntimeEntityRole | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    type: value.type,
  };
}
