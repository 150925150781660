/* tslint:disable */
/* eslint-disable */
/**
 * Alder Hey Chat API
 * This RESTful API provides an interface to the Alder Hey chatbot.
 *
 * The version of the OpenAPI document: abbb7b6
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  EchoHTTPError,
  EchoHTTPErrorFromJSON,
  EchoHTTPErrorToJSON,
  ViewmodelAddConversation,
  ViewmodelAddConversationFromJSON,
  ViewmodelAddConversationResponse,
  ViewmodelAddConversationResponseFromJSON,
  ViewmodelAddConversationResponseToJSON,
  ViewmodelAddConversationToJSON,
  ViewmodelPatchConversation,
  ViewmodelPatchConversationFromJSON,
  ViewmodelPatchConversationToJSON,
} from "../models";
import * as runtime from "../runtime";

export interface AddConversationRequest {
  addConversation: ViewmodelAddConversation;
  aHTenantName?: string;
}

export interface PatchConversationRequest {
  id: string;
  patchConversation: ViewmodelPatchConversation;
  aHTenantName?: string;
}

/**
 * no description
 */
export class ConversationsApi extends runtime.BaseAPI {
  /**
   * Start up new conversation with the Alder Hey chatbot.
   * Start new conversation
   */
  async addConversationRaw(
    requestParameters: AddConversationRequest
  ): Promise<runtime.ApiResponse<ViewmodelAddConversationResponse>> {
    if (
      requestParameters.addConversation === null ||
      requestParameters.addConversation === undefined
    ) {
      throw new runtime.RequiredError(
        "addConversation",
        "Required parameter requestParameters.addConversation was null or undefined when calling addConversation."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      requestParameters.aHTenantName !== undefined &&
      requestParameters.aHTenantName !== null
    ) {
      headerParameters["AH-Tenant-Name"] = String(
        requestParameters.aHTenantName
      );
    }

    const response = await this.request({
      path: `/conversations`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ViewmodelAddConversationToJSON(requestParameters.addConversation),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ViewmodelAddConversationResponseFromJSON(jsonValue)
    );
  }

  /**
   * Start up new conversation with the Alder Hey chatbot.
   * Start new conversation
   */
  async addConversation(
    requestParameters: AddConversationRequest
  ): Promise<ViewmodelAddConversationResponse> {
    const response = await this.addConversationRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update existing conversation, e.g. adding a user score.
   * Update conversation
   */
  async patchConversationRaw(
    requestParameters: PatchConversationRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling patchConversation."
      );
    }

    if (
      requestParameters.patchConversation === null ||
      requestParameters.patchConversation === undefined
    ) {
      throw new runtime.RequiredError(
        "patchConversation",
        "Required parameter requestParameters.patchConversation was null or undefined when calling patchConversation."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      requestParameters.aHTenantName !== undefined &&
      requestParameters.aHTenantName !== null
    ) {
      headerParameters["AH-Tenant-Name"] = String(
        requestParameters.aHTenantName
      );
    }

    const response = await this.request({
      path: `/conversations/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PATCH",
      headers: headerParameters,
      query: queryParameters,
      body: ViewmodelPatchConversationToJSON(
        requestParameters.patchConversation
      ),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Update existing conversation, e.g. adding a user score.
   * Update conversation
   */
  async patchConversation(
    requestParameters: PatchConversationRequest
  ): Promise<void> {
    await this.patchConversationRaw(requestParameters);
  }
}
