import { History as TimeoutIcon } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  styled,
} from "@mui/material";
import React, { FunctionComponent } from "react";

import { useAppContext } from "../AppContext";
import { useBrand } from "../brands/Brand";

interface SessionExpiredDialogProps {
  open: boolean;
  onClose: () => void;
}
interface StyledDialogProps {
  isFullscreen: boolean;
}
const StyledDialog = styled(Dialog, {
  shouldForwardProp: prop => prop !== "isFullscreen",
})<StyledDialogProps>(({ isFullscreen }) => ({
  borderRadius: isFullscreen ? undefined : 20,
}));
const StyledTimeoutIcon = styled(TimeoutIcon)(() => ({
  verticalAlign: "text-bottom",
  fontSize: 35,
  marginRight: 5,
}));
export const SessionExpiredDialog: FunctionComponent<
  SessionExpiredDialogProps
> = ({ open, onClose }) => {
  const brand = useBrand();
  const { isFullscreen } = useAppContext();

  return (
    <StyledDialog
      isFullscreen={isFullscreen}
      disablePortal
      open={open}
      onClose={onClose}
      aria-labelledby="ah-session-expired-title"
      aria-describedby="ah-session-expired-description"
      disableEscapeKeyDown
    >
      <DialogTitle id="ah-session-expired-title">
        <Typography variant="h6" component="div">
          <StyledTimeoutIcon />
          You&apos;ve been logged out due to inactivity
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="ah-session-expired-description">
          Your session has expired due to inactivity. To continue talking to{" "}
          {brand.botName}, press the button below to restart your session.
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained">
          OK
        </Button>
      </DialogActions>
    </StyledDialog>
    // </div>
  );
};
