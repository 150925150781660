import React, { FunctionComponent } from "react";

import { Assistantv2SearchResult } from "../api_client";
import { MessageSide } from "../typings/chat_types";
import { ChatbotMessageSearchCardGrid } from "./ChatbotMessageSearchCardGrid";
import { ChatbotMessageText } from "./ChatbotMessageText";

interface ChatbotMessageSearchProps {
  isFirstItem?: boolean;
  results: Assistantv2SearchResult[];
  header?: string;
  side: MessageSide;
  numResultsToDisplay: number;
}

const noResultsFoundText = "I searched my knowledge base and found no results.";

export const ChatbotMessageSearch: FunctionComponent<
  ChatbotMessageSearchProps
> = ({ isFirstItem = false, results, header, side, numResultsToDisplay }) => {
  return results ? (
    <>
      {header && (
        <ChatbotMessageText message={header} isFirstItem={isFirstItem} />
      )}
      <ChatbotMessageSearchCardGrid
        results={results}
        side={side}
        numResultsToDisplay={numResultsToDisplay}
      />
    </>
  ) : (
    <ChatbotMessageText
      message={noResultsFoundText}
      isFirstItem={isFirstItem}
    />
  );
};
